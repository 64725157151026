import { BAR_CHART_TYPES, ChartColumnInfo, DrilldownColumnType } from 'constants/types';
import { DrilldownSourceInfo } from 'reducers/drilldownsReducer';
import { DashboardHierarchy, DashboardVariable, DashboardVariableMap } from 'types/dashboardTypes';
import { DataPanelTemplate } from 'types/dataPanelTemplate';

export const getParentDashboardNames = (
  currentSourceInfos: DrilldownSourceInfo[],
  dashboardHierarchy: DashboardHierarchy,
): Set<string> => {
  const parentDashboardNames = new Set<string>();
  currentSourceInfos.forEach((sourceInfo) => {
    const dashboard = dashboardHierarchy.dashboards[sourceInfo.sourceDashboardId];
    if (!dashboard) {
      return;
    }
    parentDashboardNames.add(dashboard.name);
  });
  return parentDashboardNames;
};

/**
 * Retrieves all the dashboard drilldown variables that are applicable for the current dashboard.
 * Dashboard drilldown variables have the format
 * "parentDashboardName.sourceDataPanelProvidedId.drilldownColumnType".
 * @param variables The current set variables
 * @param currentSourceInfos The drilldowns source info for the current dashboard. This array
 *     contains the current ancestor dashboards in order (e.g. the first index is the original
 *     dashboard that was drilled down from)
 * @param additionalVariableFilterFn An additional filter function that can be used to filter out
 *     particular variables (e.g. only variables of a particular type).
 */
export const retrieveDrilldownVariables = (
  variables: DashboardVariableMap,
  currentSourceInfos: DrilldownSourceInfo[],
  dashboardHierarchy: DashboardHierarchy,
  additionalVariableFilterFn: (
    sourceDashboardId: number,
    sourceDataPanelProvidedId: string,
    drilldownColumnType: DrilldownColumnType,
  ) => boolean,
): { key: string; value: DashboardVariable }[] => {
  const drilldownVariables: { key: string; value: DashboardVariable }[] = [];
  const parentDashboardNameToIdMap = new Map<string, number>();
  for (const sourceInfo of currentSourceInfos) {
    const ancestorDashboard = dashboardHierarchy.dashboards[sourceInfo.sourceDashboardId];
    if (ancestorDashboard) {
      const ancestorDashboardName = ancestorDashboard.name;
      parentDashboardNameToIdMap.set(ancestorDashboardName, sourceInfo.sourceDashboardId);
    }
  }

  const parentDashboardNames = new Set(parentDashboardNameToIdMap.keys());
  Object.keys(variables).forEach((variableId: string) => {
    const variableIdSplitByDot = variableId.split('.');
    if (variableIdSplitByDot.length !== 3) {
      // The expected format for drilldown variables is
      // "dashboardName.dataPanelProvidedId.drilldownColumnType"
      return;
    }

    const variableDashboardName = variableIdSplitByDot[0];
    if (parentDashboardNames.has(variableDashboardName)) {
      if (
        additionalVariableFilterFn(
          parentDashboardNameToIdMap.get(variableDashboardName) || -1,
          variableIdSplitByDot[1],
          convertDrilldownColumnVariableNameToDrilldownColumnType(variableIdSplitByDot[2]),
        )
      ) {
        drilldownVariables.push({ key: variableId, value: variables[variableId] });
      }
    }
  });

  return drilldownVariables;
};

const convertDrilldownColumnVariableNameToDrilldownColumnType = (
  drilldownColumnVariableName: string,
): DrilldownColumnType => {
  const columnTypeString = drilldownColumnVariableName.split('_')[1];
  if (!columnTypeString) {
    throw new Error('Incorrectly formatted column variable name');
  }

  return columnTypeString.toUpperCase() as DrilldownColumnType;
};

/**
 * @returns The breakdown columns (e.g. for two dimensional charts, the category and color
 * (grouping)) for the given data panel.
 */
export const getDataPanelBreakdownColumns = (dataPanel: DataPanelTemplate): ChartColumnInfo[] => {
  const breakdownColumns: ChartColumnInfo[] = [];
  const visualizationOperation = dataPanel.visualize_op;
  if (BAR_CHART_TYPES.has(visualizationOperation.operation_type)) {
    const twoDimensionalChartInstructions =
      visualizationOperation.instructions.V2_TWO_DIMENSION_CHART;
    const categoryColumn = twoDimensionalChartInstructions?.categoryColumn;
    if (categoryColumn) {
      breakdownColumns.push(categoryColumn.column);
    }
    twoDimensionalChartInstructions?.colorColumnOptions?.forEach((colorColumnOption) => {
      breakdownColumns.push(colorColumnOption.column);
    });
  }

  return breakdownColumns;
};

export const areColumnsSubsets = (
  maybeSubsetColumns: ChartColumnInfo[],
  allColumns: ChartColumnInfo[],
): boolean => {
  return maybeSubsetColumns.every((column, index) => column.name === allColumns[index].name);
};
