import { v4 as uuid } from 'uuid';

import { createComputedView, createFolder } from './fakeDataFactory';
import { Folder, TtlEviction } from './types';

const ROOT_FOLDER_PATH = '/';

const BASE_TTL_EVICTION_POLICY: TtlEviction = {
  '@type': 'ttl',
  ttl: '1000',
};

const DOGECOIN_COMPUTED_VIEW_NAME = 'Doge Coin';

const BITCOIN_COMPUTED_VIEW_NAME = 'Bitcoin';

const ETHEREUM_COMPUTED_VIEW_NAME = 'Ethereum';

const ROOT_FOLDER_COMPUTED_VIEWS = [
  createComputedView(
    uuid(),
    DOGECOIN_COMPUTED_VIEW_NAME,
    ROOT_FOLDER_PATH + DOGECOIN_COMPUTED_VIEW_NAME,
    'The Bestest Coin',
    'SELECT * FROM rocketship',
    BASE_TTL_EVICTION_POLICY,
  ),
  createComputedView(
    uuid(),
    BITCOIN_COMPUTED_VIEW_NAME,
    ROOT_FOLDER_PATH + BITCOIN_COMPUTED_VIEW_NAME,
    'The Best Coin',
    'SELECT * FROM ogcrypto',
    BASE_TTL_EVICTION_POLICY,
  ),
  createComputedView(
    uuid(),
    ETHEREUM_COMPUTED_VIEW_NAME,
    ROOT_FOLDER_PATH + ETHEREUM_COMPUTED_VIEW_NAME,
    'The Best Coin',
    'SELECT * FROM newwavecrypto',
    BASE_TTL_EVICTION_POLICY,
  ),
];

const DANS_WINES_COMPUTED_VIEW_NAME = "Dan's Wines";

const EXPLO_FOLDER_COMPUTED_VIEWS = [
  createComputedView(
    uuid(),
    DANS_WINES_COMPUTED_VIEW_NAME,
    '/explo/' + DANS_WINES_COMPUTED_VIEW_NAME,
    'The Best Wine',
    'SELECT * FROM dans palate',
    BASE_TTL_EVICTION_POLICY,
  ),
];

const EXPLO_FOLDER = createFolder('/explo', [], EXPLO_FOLDER_COMPUTED_VIEWS);

export const ROOT_FOLDER: Folder = createFolder(
  ROOT_FOLDER_PATH,
  [EXPLO_FOLDER],
  ROOT_FOLDER_COMPUTED_VIEWS,
);

// Used so we can easily search and find the correct view from its id
export const FLAT_COMPUTED_VIEWS = ROOT_FOLDER_COMPUTED_VIEWS.concat(EXPLO_FOLDER_COMPUTED_VIEWS);
