import { FC, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';

import { ROUTES } from 'constants/routes';
import { DashboardDatasetEditor } from 'pages/dashboardPage/dashboardDatasetEditor/dashboardDatasetEditor';

import { FLAT_COMPUTED_VIEWS } from './fakeData/fakeData';

interface DatasetLocationState {
  viewId: string;
}

export const DatasetPage: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const viewId = (location.state as DatasetLocationState).viewId;

  const view = useMemo(() => FLAT_COMPUTED_VIEWS.find((view) => view.id === viewId), [viewId]);
  if (!view) return null;

  // TODO(tarastentz): convert the view to the embeddbo dataset and pull all of the relevant dataset info

  return (
    // TODO(tarastentz): pass in the correct props
    <DashboardDatasetEditor
      activeDatasetConfig={{ table_name: 'fake', parent_schema_id: 1, id: 'fake' }}
      activeDatasetData={null}
      activeDatasetSavedSchema={[]}
      activeDatasetSchema={[]}
      activeQuery={view.query}
      datasetConfigs={{}}
      fetchData={() => null}
      onReturnFn={() =>
        history.push({
          pathname: ROUTES.DATA_LIBRARY,
        })
      }
      onSave={() => null}
      onSaveDraft={() => null}
      onSelectSchema={() => null}
      parentSchemas={[]}
      selectedDatasetId={view.id ?? null}
      setSelectedDatasetId={() => null}
    />
  );
};
