import { FC, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { Dataset } from 'actions/datasetActions';
import { ViewingAsCustomerSelector } from 'components/ViewingAsCustomerSelector';
import { Button, Select, sprinkles } from 'components/ds';
import { SelectItems } from 'components/ds/Select';
import { getEditPageDataPanels, getEditPageElements } from 'reducers/dashboardEditConfigReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getDatasetName } from 'utils/naming';
import { sortBy } from 'utils/standard';

import { ModalStatus } from './constants';
import { getDataPanelUsageTextMap } from './utils';

type Props = {
  datasetConfigs: Record<string, Dataset>;
  selectedDatasetId: string | null;
  setSelectedDatasetId: (datasetId: string | null) => void;
  setModalStatus: (status: ModalStatus) => void;
  onReturnFn: () => void;
};

export const DatasetSelectTopBar: FC<Props> = ({
  datasetConfigs,
  selectedDatasetId,
  setSelectedDatasetId,
  setModalStatus,
  onReturnFn,
}) => {
  const { elements, dataPanels } = useSelector(
    (state: ReduxState) => ({
      elements: getEditPageElements(state.dashboardEditConfig, false),
      dataPanels: getEditPageDataPanels(state.dashboardEditConfig, false),
    }),
    shallowEqual,
  );

  const usageTextMap = useMemo(
    () => getDataPanelUsageTextMap(datasetConfigs, dataPanels, elements),
    [datasetConfigs, elements, dataPanels],
  );

  const dropdownOptions: SelectItems<string> = useMemo(() => {
    const sortedDatasets = sortBy(Object.values(datasetConfigs), (dataset) =>
      dataset.table_name.toLocaleLowerCase(),
    );
    return sortedDatasets.map((dataset) => {
      const datasetId = dataset.id;
      return {
        label: getDatasetName(dataset),
        value: datasetId,
        icon: 'table',
        secondaryLabel: usageTextMap[datasetId],
      };
    });
  }, [datasetConfigs, usageTextMap]);

  return (
    <div
      className={sprinkles({ flexItems: 'column', gap: 'sp2', padding: 'sp2', overflowX: 'auto' })}>
      <div className={sprinkles({ flexItems: 'alignCenterBetween', height: 'fill' })}>
        <Button icon="arrow-left" onClick={onReturnFn} variant="tertiary">
          Return to datasets
        </Button>
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1', height: 'fill' })}>
          <div className={sprinkles({ body: 'b2' })}>Viewing as</div>
          <ViewingAsCustomerSelector />
          <div
            className={sprinkles({ height: 'fill', backgroundColor: 'outline' })}
            style={{ width: 1 }}
          />
          <Button
            icon="pencil"
            onClick={() => setModalStatus(ModalStatus.EDIT_DATASET)}
            variant="tertiary">
            Rename
          </Button>
          <Button
            icon="trash-can-reg"
            onClick={() => setModalStatus(ModalStatus.DELETE_DATASET)}
            variant="tertiary">
            Delete
          </Button>
        </div>
      </div>
      {selectedDatasetId !== null ? (
        <Select
          onChange={setSelectedDatasetId}
          selectedValue={selectedDatasetId}
          values={dropdownOptions}
          variant="secondary"
        />
      ) : null}
    </div>
  );
};
