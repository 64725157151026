import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MoveResources } from 'components/MoveResources';
import { RenameModal } from 'components/RenameModal';
import { AlertModal, APP_PORTAL_ID, Menu, MenuActionItem, MenuSeparator } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';
import { deleteFolder } from 'reducers/thunks/resourceThunks';
import { ResourcePageType, Folder } from 'types/exploResource';

import * as styles from './styles.css';

enum FolderModal {
  RENAME = 'rename',
  DELETE = 'delete',
  MOVE = 'move',
}

type Props = {
  trigger: JSX.Element;
  pageType: ResourcePageType;
  folder: Folder;
  setLoadingStateForFolder?: (isLoading: boolean) => void;
};

export const FolderConfigurationMenu: FC<Props> = ({
  folder,
  pageType,
  setLoadingStateForFolder,
  trigger,
}) => {
  const dispatch = useDispatch();

  const isExploreProduct = pageType === ResourcePageType.EXPLORE;
  const currentFolderId = useSelector(
    (state: ReduxState) => state.folder.breadcrumbs[state.folder.breadcrumbs.length - 1]?.id,
  );

  const [openModal, setOpenModal] = useState<FolderModal>();

  const onDeleteForAllUsers = () => {
    setLoadingStateForFolder?.(true);
    setOpenModal(undefined);
    dispatch(deleteFolder(folder.id));
  };

  const deleteFolderDisabled = folder.num_resources > 0;
  return (
    <>
      <Menu
        align="end"
        className={styles.resourceConfigMenu}
        portalContainerId={APP_PORTAL_ID}
        trigger={trigger}>
        <MenuActionItem
          iconName="edit"
          onSelect={() => setOpenModal(FolderModal.RENAME)}
          text="Rename"
        />
        <MenuActionItem
          iconName="arrow-right"
          onSelect={() => setOpenModal(FolderModal.MOVE)}
          text="Move"
        />
        <>
          <MenuSeparator />
          <MenuActionItem
            isDestructive
            disabled={deleteFolderDisabled}
            iconName="trash"
            onSelect={() => setOpenModal(FolderModal.DELETE)}
            text="Delete"
            tooltipProps={
              deleteFolderDisabled
                ? {
                    text: 'Cannot delete non-empty folders',
                    portalContainerId: APP_PORTAL_ID,
                    side: 'bottom',
                  }
                : undefined
            }
          />
        </>
      </Menu>
      {openModal === 'move' ? (
        <MoveResources
          modalOpen
          closeModal={() => setOpenModal(undefined)}
          isExploreProduct={isExploreProduct}
          portalContainerId={APP_PORTAL_ID}
          resource={folder}
        />
      ) : undefined}
      {openModal === FolderModal.RENAME ? (
        <RenameModal
          isOpen
          closeModal={() => setOpenModal(undefined)}
          currentFolderId={currentFolderId}
          isExploreProduct={isExploreProduct}
          isFolder={true}
          resource={folder}
          setLoadingStateForResource={setLoadingStateForFolder}
          setOpenModal={() => setOpenModal(FolderModal.RENAME)}
        />
      ) : null}
      <AlertModal
        actionButtonProps={{ text: 'Delete for all users', onClick: onDeleteForAllUsers }}
        isOpen={openModal === 'delete'}
        onClose={() => setOpenModal(undefined)}
        portalContainerId={APP_PORTAL_ID}
        title={`Are you sure you want to delete this ${
          isExploreProduct ? 'dashboard' : 'report builder'
        }?`}
      />
    </>
  );
};
