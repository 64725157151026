import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ConfigSection } from 'components/PanelComponents/ConfigSection';
import { PanelListItem } from 'components/PanelComponents/PanelListItem';
import { FILTER_ELEMENTS } from 'constants/dashboardConstants';
import { ReduxState } from 'reducers/rootReducer';
import {
  getCurrentDashboardNonStickyHeaderElementsWithDrilldowns,
  getStickyHeaderElementsWithDrilldowns,
} from 'reducers/selectors';
import {
  DASHBOARD_ELEMENT_TYPES,
  DashboardVariable,
  DashboardVariableMap,
  SliderElementConfig,
  SliderElementValue,
} from 'types/dashboardTypes';
import { getListOfExtraVarsForElement } from 'utils/extraVariableUtils';
import { getSliderThumbVariableName } from 'utils/sliderUtils';
import { sortBy } from 'utils/standard';
import { getVariableIcon } from 'utils/variableUtils';

type Props = { variables: DashboardVariableMap; searchQuery: string };

export const FilterVariablesSection: FC<Props> = ({ variables, searchQuery }) => {
  const { dashboardNonHeaderElements, stickyHeaderElements } = useSelector((state: ReduxState) => {
    return {
      dashboardNonHeaderElements: getCurrentDashboardNonStickyHeaderElementsWithDrilldowns(state),
      stickyHeaderElements: getStickyHeaderElementsWithDrilldowns(state),
    };
  });

  const allElements = useMemo(
    () => dashboardNonHeaderElements.concat(stickyHeaderElements),
    [dashboardNonHeaderElements, stickyHeaderElements],
  );

  const sortedElements = useMemo(
    () => sortBy(allElements, (elem) => elem.name.toLowerCase()),
    [allElements],
  );

  const sortedFilteredElements = useMemo(() => {
    return sortedElements.filter(
      (element) =>
        FILTER_ELEMENTS.has(element.element_type) &&
        element.name.toLowerCase().includes(searchQuery),
    );
  }, [sortedElements, searchQuery]);

  const viewVariable = (
    varName: string,
    value: DashboardVariable,
    elementType?: DASHBOARD_ELEMENT_TYPES,
  ) => (
    <PanelListItem
      copiable
      key={varName}
      leftIcon={getVariableIcon(value, elementType)}
      name={varName}
      rightElement={JSON.stringify(value)}
    />
  );

  return (
    <ConfigSection
      defaultOpen={!!sortedFilteredElements.length}
      icon="filters"
      title="Filter variables"
      variant="header2">
      {sortedFilteredElements.map(({ element_type, name, config }) => {
        const value = variables[name];
        if (element_type === DASHBOARD_ELEMENT_TYPES.DATE_RANGE_PICKER) {
          const valueObj = (value ?? { startDate: undefined, endDate: undefined }) as Record<
            string,
            DashboardVariable
          >;
          return Object.keys(valueObj).map((key) =>
            viewVariable(`${name}.${key}`, valueObj[key], element_type),
          );
        }
        if (element_type === DASHBOARD_ELEMENT_TYPES.SLIDER) {
          const numThumbs = (config as SliderElementConfig).numThumbs;
          return [...Array(numThumbs)].map((_, i) => {
            const thumbVariableName = getSliderThumbVariableName(i);
            return viewVariable(
              `${name}.${thumbVariableName}`,
              (value as SliderElementValue)?.[thumbVariableName],
              element_type,
            );
          });
        }
        return [
          viewVariable(name, value, element_type),
          getListOfExtraVarsForElement(name, element_type).map((extraVar) => {
            const extraValue = variables[extraVar];
            return extraValue !== undefined ? viewVariable(extraVar, extraValue) : null;
          }),
        ];
      })}
    </ConfigSection>
  );
};
