export interface TtlEviction {
  '@type': 'ttl';
  ttl: string;
}

export const COMPUTED_VIEW_TYPE = 'computed-view';

export const FOLDER_TYPE = 'folder';

export interface ComputedView {
  name: string;
  path: string;
  description: string | null;
  // This is simplified from the actual definition.
  columnDefinitions: Array<string>;
  readonly id?: string | null;
  readonly versionId?: string | null;
  readonly namespaceId?: string | null;
  '@type': typeof COMPUTED_VIEW_TYPE;
  query: string;
  cacheable?: boolean;
  // This is simplified from the actual definition.
  variables: Array<string>;
  cacheEvictionPolicy: TtlEviction | null;
}

export interface Folder {
  '@type': typeof FOLDER_TYPE;
  path: string;
  content: Array<Folder | ComputedView> | null;
}
