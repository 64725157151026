import cx from 'classnames';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { sprinkles } from 'components/ds';
import { LatestVersionInfo, Resource } from 'types/exploResource';

import * as styles from './styles.css';

interface Props {
  resource: Resource;
  isDisabled: boolean;
  isLoading: boolean;
  onClickUrl: string;
  latestConfig: LatestVersionInfo;
  dotsMenu: JSX.Element | null;
  lastModifiedMessage: string;
  hasEmailState: boolean;
  renderPreviewFn: (latestVersion: LatestVersionInfo | undefined) => JSX.Element;
  renderLatestVersionTagFn: (
    latestVersion: Pick<LatestVersionInfo, 'is_draft' | 'version_number'>,
  ) => JSX.Element;
  renderEmailTagFn: () => JSX.Element;
}

export const ResourceItemCard: FC<Props> = ({
  resource,
  isDisabled,
  isLoading,
  onClickUrl,
  latestConfig,
  dotsMenu,
  lastModifiedMessage,
  hasEmailState,
  renderPreviewFn,
  renderLatestVersionTagFn,
  renderEmailTagFn,
}) => {
  const history = useHistory();

  return (
    <div
      className={cx(styles.resourceCardItem, {
        [styles.disabledResource]: isDisabled,
      })}
      onClick={isDisabled || isLoading ? undefined : () => history.push(onClickUrl)}>
      <div className={styles.cardPreviewContainer}>
        <div className={styles.cardPreview}>
          {renderPreviewFn(latestConfig as LatestVersionInfo)}
        </div>
        {dotsMenu}
      </div>

      <div className={styles.resourceCardInfo}>
        <div className={styles.resourceCardName}>{resource.name}</div>
        <div className={sprinkles({ flexItems: 'alignCenter', gap: 'sp1' })}>
          {resource.latest_versions.map(renderLatestVersionTagFn)}
          <div className={styles.resourceCardLastModified}>{lastModifiedMessage}</div>
        </div>
        {hasEmailState ? renderEmailTagFn() : null}
      </div>
    </div>
  );
};
