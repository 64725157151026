import { QueryExecutionResponse } from '@explo-tech/fido-api';
import { useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import {
  saveDatasetQuery,
  saveDraftDatasetQuery,
  updateDashboardDatasetSchema,
} from 'actions/datasetActions';
import { FetchDashboardDatasetPreviewData } from 'actions/responseTypes';
import { Poller } from 'components/JobQueue/Poller';
import { setSelectedDatasetId } from 'reducers/dashboardEditConfigReducer';
import { receiveFinishedJobs } from 'reducers/dashboardLayoutReducer';
import { getParentSchemasList } from 'reducers/parentSchemaReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getEditableDatasets } from 'reducers/selectors';
import {
  fetchEditorDatasetPreviewThunk,
  fetchSavedDatasetThunk,
} from 'reducers/thunks/dashboardDataThunks/fetchDatasetPreviewThunks';
import { showDuplicateColumnNameToast } from 'shared/sharedToasts';
import { EVENTS, trackEvent } from 'telemetry/exploAnalytics';
import { getDuplicateColumnsFromSchema } from 'utils/queryUtils';

import { DashboardDatasetEditor } from './dashboardDatasetEditor';
import { returnToDatasetsPage } from './utils';

export const DashboardDatasetEditorDataFetcher = () => {
  const dispatch = useDispatch();

  const { datasets, parentSchemas, datasetData, awaitedJobs, selectedDatasetId } = useSelector(
    (state: ReduxState) => ({
      shouldUseJobQueue: !!state.currentUser.team?.feature_flags.use_job_queue,
      datasets: getEditableDatasets(state),
      parentSchemas: getParentSchemasList(state),
      datasetData: state.dashboardEditConfig.datasetData,
      awaitedJobs: state.dashboardLayout.awaitedJobs,
      selectedDatasetId: state.dashboardEditConfig.selectedDatasetId,
    }),
    shallowEqual,
  );

  const parentSchemaId =
    selectedDatasetId && datasets ? datasets[selectedDatasetId].parent_schema_id : undefined;

  const getUnderlyingData = useCallback(
    (
      query: string,
      pageNumber?: number,
      onSuccess?: (data: QueryExecutionResponse | FetchDashboardDatasetPreviewData) => void,
    ) => {
      if (!selectedDatasetId || !parentSchemaId) return;

      dispatch(
        fetchEditorDatasetPreviewThunk(
          {
            selectedDatasetId,
            query,
            parentSchemaId: parentSchemaId,
          },
          pageNumber,
          onSuccess,
        ),
      );
    },
    [parentSchemaId, dispatch, selectedDatasetId],
  );

  const onSaveQuery = useCallback(
    (query: string) => {
      if (!selectedDatasetId) return;

      getUnderlyingData(query, undefined, (data) => {
        const previewData = (data as FetchDashboardDatasetPreviewData).dataset_preview;
        dispatch(
          saveDatasetQuery({
            query: query,
            dataset_id: selectedDatasetId,
            schema: previewData.schema,
          }),
        );
        dispatch(fetchSavedDatasetThunk(selectedDatasetId));

        trackEvent(EVENTS.SAVED_QUERY, {
          dataset_id: selectedDatasetId,
          dataset_query: query,
        });

        showDuplicateColumnNameToast(getDuplicateColumnsFromSchema(previewData.schema));
      });
    },
    [dispatch, getUnderlyingData, selectedDatasetId],
  );

  const onSaveQueryDraft = (query: string | undefined) => {
    if (!selectedDatasetId) return;

    dispatch(saveDraftDatasetQuery({ queryDraft: query, dataset_id: selectedDatasetId }));
  };

  const onSelectSchema = (schemaId: number | string) => {
    if (!selectedDatasetId) return;

    dispatch(
      updateDashboardDatasetSchema({
        datasetId: selectedDatasetId,
        newParentSchemaId: schemaId as number,
      }),
    );
  };

  const selectedDataset = datasets ? datasets[selectedDatasetId ?? ''] : null;
  const data = selectedDatasetId ? datasetData[selectedDatasetId] : undefined;

  return (
    <>
      <DashboardDatasetEditor
        activeDatasetConfig={selectedDataset}
        activeDatasetData={data ?? null}
        activeDatasetSavedSchema={selectedDataset?.schema ?? []}
        activeDatasetSchema={data?.schema ?? selectedDataset?.schema ?? []}
        activeQuery={selectedDataset?.queryDraft ?? selectedDataset?.query ?? ''}
        datasetConfigs={datasets ?? {}}
        fetchData={getUnderlyingData}
        onReturnFn={() => returnToDatasetsPage(dispatch)}
        onSave={onSaveQuery}
        onSaveDraft={onSaveQueryDraft}
        onSelectSchema={onSelectSchema}
        parentSchemas={parentSchemas}
        selectedDatasetId={selectedDatasetId}
        setSelectedDatasetId={(datasetId) => {
          dispatch(setSelectedDatasetId(datasetId));

          if (!datasets || !datasetId) return;

          trackEvent(EVENTS.SELECTED_DATASET, {
            dataset_id: datasetId,
            dataset_name: datasets[datasetId].table_name,
          });
        }}
      />
      <Poller
        awaitedJobs={awaitedJobs}
        updateJobResult={(finishedJobIds, onComplete) => {
          if (finishedJobIds.length > 0) dispatch(receiveFinishedJobs(finishedJobIds));

          onComplete();
        }}
      />
    </>
  );
};
