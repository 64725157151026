import { FC, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { ConfigSection } from 'components/PanelComponents/ConfigSection';
import { PanelListItem } from 'components/PanelComponents/PanelListItem';
import { sprinkles } from 'components/ds';
import { getSelectedCustomer } from 'reducers/customersReducer';
import { ReduxState } from 'reducers/rootReducer';
import * as RD from 'remotedata';
import { getCustomerVariables } from 'utils/customerUtils';
import { partition } from 'utils/standard';

type Props = { searchQuery: string };

export const CustomerVariablesSection: FC<Props> = ({ searchQuery }) => {
  const { selectedCustomer, archetypeProperties, hierarchyMetadata } = useSelector(
    (state: ReduxState) => ({
      selectedCustomer: getSelectedCustomer(state.customers),
      archetypeProperties: state.teamData.data?.archetype_properties,
      hierarchyMetadata: state.customers.hierarchyMetadata,
    }),
    shallowEqual,
  );

  const [filteredCustomerVariables, inheritedProperties] = useMemo(() => {
    if (!selectedCustomer) return [[], new Set()];

    const filteredVars: Record<string, string> = {};
    const inheritedProps = new Set();

    const customerVariables = getCustomerVariables(
      selectedCustomer,
      new Set(archetypeProperties?.map((prop) => prop.name)),
    );
    const deprecatedCustomerVariables = new Set(
      archetypeProperties?.filter((property) => property.deprecated).map((prop) => prop.name),
    );
    const childProperties = new Set(Object.keys(selectedCustomer?.properties ?? []));

    Object.entries(customerVariables).forEach(([key, value]) => {
      if (
        !deprecatedCustomerVariables.has(key) &&
        !key.startsWith('user_group.') &&
        !key.startsWith('customer.') &&
        key.toLowerCase().includes(searchQuery)
      ) {
        filteredVars[key] = value;
        if (key.startsWith('properties.') && !childProperties.has(key.replace('properties.', ''))) {
          inheritedProps.add(key);
        }
      }
    });
    return [Object.entries(filteredVars), inheritedProps];
  }, [selectedCustomer, archetypeProperties, searchQuery]);

  const inheritedArchetypes = useMemo(() => {
    const parentArchetypes = new Set();
    if (!archetypeProperties || !selectedCustomer) return parentArchetypes;
    archetypeProperties.forEach((property) => {
      if (property.hierarchy_level_id === selectedCustomer?.hierarchy_level_id) return;
      parentArchetypes.add(property.name);
    });
    return parentArchetypes;
  }, [archetypeProperties, selectedCustomer]);

  const [inheritedVars, nonInheritedVars] = useMemo(
    () =>
      partition(
        filteredCustomerVariables,
        ([key]) => inheritedArchetypes.has(key) || inheritedProperties.has(key),
      ),
    [filteredCustomerVariables, inheritedProperties, inheritedArchetypes],
  );

  const titleName = useMemo(() => {
    if (!selectedCustomer || !RD.isSuccess(hierarchyMetadata)) return 'Customer';
    const level = hierarchyMetadata.data.levels.find(
      (level) => level.id === selectedCustomer.hierarchy_level_id,
    );
    return level?.name || 'Customer';
  }, [selectedCustomer, hierarchyMetadata]);

  return (
    <>
      <ConfigSection
        defaultOpen
        icon="user-group"
        infoText='All "user_group" variables are deprecated and have been replaced by archetype property variables. Custom properties are now stored in the "properties" object'
        title={`${titleName} variables`}
        variant="header2">
        {nonInheritedVars.map(([key, value]) => {
          return (
            <PanelListItem
              copiable
              key={key}
              leftIcon="report-builder-string"
              name={key}
              rightElement={JSON.stringify(value)}
            />
          );
        })}
      </ConfigSection>
      <ConfigSection
        defaultOpen
        icon="filter-list"
        infoText={`These variables are inherited from the ${titleName.toLowerCase()}'s parent(s) if they have any`}
        title={`Inherited ${titleName.toLowerCase()} variables`}
        variant="header2">
        {inheritedVars.length ? (
          inheritedVars.map(([key, value]) => {
            return (
              <PanelListItem
                copiable
                key={key}
                leftIcon="report-builder-string"
                name={key}
                rightElement={JSON.stringify(value)}
              />
            );
          })
        ) : searchQuery ? null : (
          <div className={emptyInheritanceClass}>
            This customer doesn&apos;t inherit any values from its parent(s)
          </div>
        )}
      </ConfigSection>
    </>
  );
};

export const emptyInheritanceClass = sprinkles({
  flexItems: 'center',
  padding: 'sp2',
  body: 'b3',
  color: 'contentTertiary',
});
