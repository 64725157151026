import { FC, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';

import { Breadcrumbs, Button, sprinkles } from 'components/ds';
import {
  COMPUTED_VIEW_TYPE,
  ComputedView,
  Folder,
  FOLDER_TYPE,
} from 'pages/dataLibraryPage/fakeData/types';
import { FolderItem } from 'shared/ExploResource/FolderItem';
import { Folder as ResourceFolder, ResourceType } from 'types/exploResource';
import { murmurhash } from 'utils/standard';

import { DatasetListItem } from './DatasetListItem';

interface FolderLocationState {
  folder: Folder;
}

export const FolderPage: FC = () => {
  const location = useLocation();
  const history = useHistory();

  const folder = (location.state as FolderLocationState).folder;

  const splitFolderPath = useMemo(() => {
    const splitFolderPath = folder.path.split('/');
    // Remove the root which is just an empty string.
    splitFolderPath.splice(0, 1);
    return splitFolderPath;
  }, [folder.path]);

  const breadcrumbHierarchyItems = useMemo(() => {
    return splitFolderPath.map((path) => {
      return {
        text: path,
      };
    });
  }, [splitFolderPath]);

  const subFolders = useMemo(() => {
    const folderContent = folder.content;

    if (!folderContent) {
      return [];
    }
    return folderContent.filter((content) => content['@type'] === FOLDER_TYPE) as Folder[];
  }, [folder]);

  const computedViews = useMemo(() => {
    const folderContent = folder.content;

    if (!folderContent) {
      return [];
    }
    return folderContent.filter(
      (content) => content['@type'] === COMPUTED_VIEW_TYPE,
    ) as ComputedView[];
  }, [folder]);

  return (
    <div className={sprinkles({ marginX: 'sp8', width: 'fill' })}>
      <div
        className={sprinkles({ flexItems: 'alignCenterBetween', heading: 'h1', marginY: 'sp2' })}>
        <Breadcrumbs items={breadcrumbHierarchyItems} />
        <div className={sprinkles({ flexItems: 'center' })}>
          <Button className={sprinkles({ marginX: 'sp1' })} variant="secondary">
            Create Folder
          </Button>
          <Button variant="primary">Create Dataset</Button>
        </div>
      </div>
      <div className={sprinkles({ width: 'fill', marginY: 'sp2' })}>
        {subFolders.map((subFolder) => (
          <div className={sprinkles({ marginY: 'sp2' })} key={subFolder.path}>
            <FolderItem
              dotsMenu={null}
              folder={adaptFidoFolderToResourceFolder(subFolder)}
              isCard={false}
              openFolder={() => {
                history.push({
                  pathname: `/data-library/folder/${murmurhash(subFolder.path)}`,
                  state: { folder: subFolder },
                });
              }}
            />
          </div>
        ))}
        {
          // TODO(zifanxiang): Fully implement the computed view/dataset items element.
        }
        {computedViews.map((computedView) => (
          <div className={sprinkles({ marginY: 'sp2' })} key={computedView.id}>
            <DatasetListItem
              datasetName={computedView.name}
              onClick={() => {
                history.push({
                  pathname: `/data-library/dataset/${computedView.id}`,
                  state: { viewId: computedView.id },
                });
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const UNSET_PARENT_ID = -1;

const adaptFidoFolderToResourceFolder = (fidoFolder: Folder): ResourceFolder => {
  return {
    id: murmurhash(fidoFolder.path),
    type: ResourceType.FOLDER,
    name: fidoFolder.path.split('/').pop() || '',
    parent_id: UNSET_PARENT_ID,
    num_resources: fidoFolder.content?.length || 0,
  };
};
