import { FC } from 'react';
import { useDispatch } from 'react-redux';

import { deleteDrilldownEntryPoint } from 'actions/dashboardActions';
import { IconButton, sprinkles } from 'components/ds';
import { DrilldownEntryPointInfo } from 'types/dataPanelTemplate';

import { Column } from '../../DataConfigTab/VisualizationConfigTableColumns/Column';

interface Props {
  drilldownEntryPoints: [string, DrilldownEntryPointInfo][];
  dashboardIdToNameMap: Record<number, string>;
  selectedDataPanelId: string;
}

export const DashboardDrilldownEntryDetailSection: FC<Props> = ({
  drilldownEntryPoints,
  dashboardIdToNameMap,
  selectedDataPanelId,
}) => {
  const dispatch = useDispatch();

  return (
    <>
      {drilldownEntryPoints.map(([id, entryPoint]) => {
        const dashboardName = dashboardIdToNameMap[entryPoint.destinationDashboardId];
        return (
          <div className={sprinkles({ flexItems: 'alignCenterBetween' })} key={id}>
            <>
              <div className={sprinkles({ marginY: 'sp1' })}>
                {dashboardName ? (
                  <div>
                    Destination dashboard <b>{`${dashboardName}`}</b>
                  </div>
                ) : null}
                <div>Required columns</div>
                <div>
                  {entryPoint.sourceChartColumns.map((column) => {
                    if (!column.name || !column.type) {
                      return null;
                    }
                    return (
                      <Column displayName={column.name} key={column.name} type={column.type} />
                    );
                  })}
                </div>
              </div>
            </>
            <IconButton
              name="trash"
              onClick={() => {
                dispatch(
                  deleteDrilldownEntryPoint({
                    sourceDataPanelId: selectedDataPanelId,
                    drilldownEntryPointId: id,
                  }),
                );
              }}
            />
          </div>
        );
      })}
    </>
  );
};
