import { FC } from 'react';
import { Redirect } from 'react-router';

import { murmurhash } from 'utils/standard';

import { ROOT_FOLDER } from './fakeData/fakeData';

export const RootDataLibraryPage: FC = () => {
  const rootFolderId = murmurhash(ROOT_FOLDER.path);
  return (
    <Redirect
      to={{ pathname: `/data-library/folder/${rootFolderId}`, state: { folder: ROOT_FOLDER } }}
    />
  );
};
