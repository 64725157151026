import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { Input, sprinkles } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';

import { ChartVariablesSection } from './ChartVariablesSection';
import { CustomVariablesSection } from './CustomVariablesSection';
import { CustomerVariablesSection } from './CustomerVariablesSection';
import { DrilldownVariablesSection } from './DrilldownVariablesSection';
import { FilterVariablesSection } from './FilterVariablesSection';

type Props = {
  dashboardId: number;
  // All of the variable types are already read only except for Custom (Embed) Variables
  readOnly?: boolean;
};

export const VariablesPanel: FC<Props> = ({ dashboardId, readOnly }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const variables = useSelector((state: ReduxState) => state.dashboardData.variables ?? {});

  const loweredSearch = searchQuery.toLowerCase();

  return (
    <div className={sprinkles({ height: 'fill', overflow: 'auto' })}>
      <Input
        className={sprinkles({ padding: 'sp2' })}
        leftIcon="search"
        onChange={setSearchQuery}
        placeholder="Search..."
        value={searchQuery}
      />
      <CustomerVariablesSection searchQuery={loweredSearch} />
      <ChartVariablesSection searchQuery={loweredSearch} variables={variables} />
      <FilterVariablesSection searchQuery={loweredSearch} variables={variables} />
      <DrilldownVariablesSection lowerCasedSearchQuery={loweredSearch} variables={variables} />
      <CustomVariablesSection
        dashboardId={dashboardId}
        readOnly={readOnly}
        searchQuery={loweredSearch}
        variables={variables}
      />
    </div>
  );
};
