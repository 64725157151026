import cx from 'classnames';
import { FC } from 'react';
import { useHistory } from 'react-router-dom';

import { LatestVersionInfo, Resource } from 'types/exploResource';

import * as styles from './styles.css';

interface Props {
  resource: Resource;
  isDisabled: boolean;
  isLoading: boolean;
  showPreview: boolean;
  onClickUrl: string;
  latestConfig: LatestVersionInfo;
  lastModifiedMessage: string;
  hasEmailState: boolean;
  dotsMenu: JSX.Element | null;
  renderLatestVersionTagFn: (
    latestVersion: Pick<LatestVersionInfo, 'is_draft' | 'version_number'>,
  ) => JSX.Element;
  renderResourcePreviewFn: (latestVersion: LatestVersionInfo | undefined) => JSX.Element;
  renderEmailTagFn: () => JSX.Element;
}

export const ResourceItemListElement: FC<Props> = ({
  resource,
  isDisabled,
  isLoading,
  showPreview,
  onClickUrl,
  latestConfig,
  lastModifiedMessage,
  hasEmailState,
  dotsMenu,
  renderLatestVersionTagFn,
  renderResourcePreviewFn,
  renderEmailTagFn,
}) => {
  const history = useHistory();

  return (
    <div
      className={cx(styles.resourceListItem, {
        [styles.disabledResource]: isDisabled,
      })}
      onClick={isDisabled || isLoading ? undefined : () => history.push(onClickUrl)}>
      <div className={styles.resourceListColumnGroup}>
        {showPreview ? (
          <div className={styles.listPreviewContainer}>
            <div className={styles.listPreview}>
              {renderResourcePreviewFn(latestConfig as LatestVersionInfo)}
            </div>
          </div>
        ) : null}

        <div className={styles.resourceListName}>{resource.name}</div>
      </div>
      <div className={styles.resourceListEndGroup}>
        <div className={styles.resourceListInfo}>
          {resource.latest_versions.map(renderLatestVersionTagFn)}
          {lastModifiedMessage}
        </div>
        <div className={styles.emailContainer}>{hasEmailState ? renderEmailTagFn() : null}</div>

        {dotsMenu}
      </div>
    </div>
  );
};
