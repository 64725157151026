import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import sql from 'react-syntax-highlighter/dist/esm/languages/hljs/sql';
import sqlFormatter from 'sql-formatter';
import { useImmer } from 'use-immer';

import { Dataset, DatasetData } from 'actions/datasetActions';
import { SqlEditor } from 'components/SqlEditor';
import { Icon, Spinner, sprinkles, vars } from 'components/ds';
import { QueryRunButtons } from 'components/resource/QueryRunButtons';
import { ReduxState } from 'reducers/rootReducer';
import { getArchetypeProperties, getSchemaTablesMap } from 'reducers/selectors';
import { DatasetSchema } from 'types/datasets';
import { getSchemaNameInfo } from 'utils/queryUtils';
import { isQueryDependentOnVariable } from 'utils/variableUtils';

import { QueryRuntime } from '../DashboardDebugger/QueryRuntime';
import { QuerySuggestion } from '../DashboardDebugger/QuerySuggestion';

import { DatasetEditorNonIdealState } from './DatasetEditorNonIdealState';
import { DatasetPreview } from './DatasetPreview';
import { DatasetQueryPanel } from './DatasetQueryPanel';

SyntaxHighlighter.registerLanguage('sql', sql);

const SMALL_HEIGHT = 300;
const LARGE_HEIGHT = 500;

type Props = {
  activeQuery: string;
  currentQuery: string;
  activeDatasetData: DatasetData | null;
  activeDatasetConfig: Dataset | null;
  activeDatasetSchema: DatasetSchema | null;
  onSave: (query: string) => void;
  onSaveDraft: (query: string | undefined) => void;
  fetchData: (query: string, pageNumber?: number) => void;
  selectedDatasetId: string | null;
  setCurrentQuery: (query: string) => void;
};

type PanelStatus = {
  queryOpen: boolean;
  debuggerOpen: boolean;
  previewOpen: boolean;
};

const initialPanelStatus: PanelStatus = {
  queryOpen: true,
  debuggerOpen: false,
  previewOpen: false,
};

export const DatasetQueryPanels: FC<Props> = ({
  activeDatasetConfig,
  activeDatasetData,
  onSave,
  onSaveDraft,
  fetchData,
  selectedDatasetId,
  activeQuery,
  activeDatasetSchema,
  currentQuery,
  setCurrentQuery,
}) => {
  const { requireDatasetCustomerId, archetypeProperties, schemaTablesMap } = useSelector(
    (state: ReduxState) => ({
      requireDatasetCustomerId: state.currentUser.team?.configuration.require_dataset_customer_id,
      archetypeProperties: getArchetypeProperties(state),
      schemaTablesMap: getSchemaTablesMap(state),
    }),
    shallowEqual,
  );

  const [panelStatus, setPanelStatus] = useImmer(initialPanelStatus);
  const containerRef = useRef<HTMLDivElement>(null);

  // Scroll to bottom of query panel div when output is opened
  const scrollToBottom = useCallback(() => {
    setTimeout(
      () =>
        containerRef.current?.scrollTo({
          left: 0,
          top: document.body.scrollHeight,
          behavior: 'smooth',
        }),
      300,
    );
  }, []);

  const updatePanelView = useCallback(() => {
    setPanelStatus((draft) => {
      draft.debuggerOpen = false;
      draft.previewOpen = true;
    });
    scrollToBottom();
  }, [scrollToBottom, setPanelStatus]);

  // Re-run and save the query when the schema has changed
  const currentSelectedSchemaId = activeDatasetConfig?.parent_schema_id;
  const prevSelectedSchemaId = useRef(currentSelectedSchemaId);
  useEffect(() => {
    if (prevSelectedSchemaId.current === currentSelectedSchemaId) return;
    onSave(activeQuery);
    updatePanelView();
    prevSelectedSchemaId.current = currentSelectedSchemaId;
  }, [currentSelectedSchemaId, onSave, activeQuery, updatePanelView]);

  const getTablePreview = useCallback(() => {
    fetchData(currentQuery);
    updatePanelView();
  }, [fetchData, currentQuery, updatePanelView]);

  const formatQuery = useCallback(
    (query: string) => sqlFormatter.format(query, { indent: '    ' }),
    [],
  );

  // for key bindings
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!selectedDatasetId) return;

      if (event.metaKey && event.shiftKey && event.key === 'f') {
        onSaveDraft(formatQuery(currentQuery));
      } else if (event.metaKey && event.key === 'Enter') {
        getTablePreview();
      } else if (event.metaKey && event.shiftKey && event.key === 's') {
        onSave(currentQuery);
      }
    };

    const containerElement = containerRef.current;
    containerElement?.addEventListener('keydown', handleKeyDown);

    return () => {
      containerElement?.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    currentQuery,
    getTablePreview,
    onSave,
    onSaveDraft,
    formatQuery,
    selectedDatasetId,
    containerRef,
  ]);

  const { tableNames, columnNames } = useMemo(
    () => getSchemaNameInfo(schemaTablesMap, activeDatasetConfig?.parent_schema_id?.toString()),
    [schemaTablesMap, activeDatasetConfig?.parent_schema_id],
  );
  const missingRequiredCustomerId = useMemo(() => {
    if (!activeDatasetConfig) return;
    return (
      requireDatasetCustomerId &&
      !isQueryDependentOnVariable(archetypeProperties, {
        ...activeDatasetConfig,
        query: currentQuery,
      })
    );
  }, [requireDatasetCustomerId, activeDatasetConfig, currentQuery, archetypeProperties]);

  if (!activeDatasetConfig) return null;

  const queryInformation = activeDatasetData?.queryInformation;

  const isPreviewDisabled = !currentQuery || currentQuery.length === 0;
  const isSaveDisabled = isPreviewDisabled || missingRequiredCustomerId;

  const renderGeneratedQueryBody = () => {
    if (!selectedDatasetId || !activeDatasetConfig) {
      return <Spinner fillContainer />;
    }

    if (!queryInformation) {
      return (
        <DatasetEditorNonIdealState
          description="This panel will show the query constructed and ran based on variables in the dataset SQL"
          icon={<Icon name="rectangle-terminal" size="lg" />}
          title="No query to debug"
        />
      );
    }

    return (
      <>
        <div className={sprinkles({ paddingX: 'sp3', flexItems: 'column', gap: 'sp2' })}>
          <SyntaxHighlighter
            showLineNumbers
            customStyle={{
              backgroundColor: vars.colors.elevationMid,
              padding: 8,
              overflow: 'auto',
              borderRadius: 8,
              minHeight: SMALL_HEIGHT,
              maxHeight: LARGE_HEIGHT,
            }}
            language="sql">
            {formatQuery(queryInformation._query || '')}
          </SyntaxHighlighter>

          {!activeDatasetData?.error ? (
            <div className={sprinkles({ flexItems: 'alignCenter', width: 'fill' })}>
              <QuerySuggestion query={currentQuery} />
            </div>
          ) : null}
        </div>
      </>
    );
  };

  const hasChanges = currentQuery !== activeDatasetConfig?.query;

  const queryRunButtons = (
    <QueryRunButtons
      error={activeDatasetData?.error}
      onFormat={() => onSaveDraft(formatQuery(currentQuery))}
      onPreview={isPreviewDisabled ? undefined : getTablePreview}
      onRevertDraft={() => onSaveDraft(undefined)}
      onSave={
        isSaveDisabled
          ? undefined
          : () => {
              onSave(currentQuery);
              updatePanelView();
            }
      }
      query={activeQuery}
      saveText={hasChanges ? 'Save & Run' : 'Run'}
      saveTooltipProps={{
        text: missingRequiredCustomerId
          ? 'Query requires a customer_id'
          : hasChanges
          ? 'Preview and save query'
          : 'Preview results',
      }}
    />
  );

  return (
    <div className={outerContainer} ref={containerRef}>
      <DatasetQueryPanel
        headerElement={queryRunButtons}
        isOpen={panelStatus.queryOpen}
        text="Raw Query"
        toggleIsOpen={() =>
          setPanelStatus((draft) => {
            draft.queryOpen = !draft.queryOpen;
          })
        }>
        <SqlEditor
          columnNames={columnNames}
          minHeight={SMALL_HEIGHT}
          onChange={setCurrentQuery}
          onChangeDraft={(newQuery) =>
            onSaveDraft(newQuery === currentQuery ? undefined : newQuery)
          }
          query={currentQuery}
          tableNames={tableNames}
        />
      </DatasetQueryPanel>
      <DatasetQueryPanel
        headerElement={
          queryInformation ? (
            <QueryRuntime dashboardEditor queryInformation={queryInformation} />
          ) : undefined
        }
        isOpen={panelStatus.debuggerOpen}
        text="Executed Query"
        toggleIsOpen={() =>
          setPanelStatus((draft) => {
            draft.debuggerOpen = !draft.debuggerOpen;
          })
        }>
        {renderGeneratedQueryBody()}
      </DatasetQueryPanel>
      <DatasetQueryPanel
        isOpen={panelStatus.previewOpen}
        text="Output"
        toggleIsOpen={() =>
          setPanelStatus((draft) => {
            draft.previewOpen = !draft.previewOpen;
          })
        }>
        <div className={sprinkles({ parentContainer: 'fill' })} style={{ minHeight: LARGE_HEIGHT }}>
          <DatasetPreview
            datasetConfig={activeDatasetConfig}
            datasetData={activeDatasetData}
            datasetQuery={activeQuery}
            datasetSchema={activeDatasetSchema}
            fetchDataset={fetchData}
            getTablePreview={getTablePreview}
          />
        </div>
      </DatasetQueryPanel>
    </div>
  );
};

const outerContainer = sprinkles({
  flexItems: 'column',
  gap: 'sp1',
  parentContainer: 'fill',
  overflowY: 'scroll',
});
