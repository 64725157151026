import cx from 'classnames';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import { ReportBuilderConfig } from 'actions/reportBuilderConfigActions';
import { sprinkles } from 'components/ds';
import { AddDatasetButton } from 'components/resource/AddDatasetButton';
import { ColumnHeader } from 'components/resource/ColumnHeader';
import { DatasetPreviewTable } from 'components/resource/DatasetPreviewTable';
import { EmbedText } from 'pages/ReportBuilder/EmbedText';
import { DatasetItem } from 'pages/ReportBuilderEditor/DatasetEditor/DatasetItem';
import { SearchInput } from 'pages/manageDataTablesPage/SearchInput';
import {
  clearDuplicateColumns,
  createReportBuilderDataset,
} from 'reducers/reportBuilderEditReducer';
import { ReduxState } from 'reducers/rootReducer';
import { createComputedView } from 'reducers/thunks/fidoThunks';
import * as RD from 'remotedata';
import { fetchAppDataset } from 'reportBuilderContent/thunks/appDataThunks';
import { showDuplicateColumnNameToast } from 'shared/sharedToasts';
import { ResourcePageType } from 'types/exploResource';
import { filterReportBuilderDatasets } from 'utils/adHocUtils';
import { getReportBuilderSchema } from 'utils/reportBuilderConfigUtils';
import { useQuery } from 'utils/routerUtils';
import { sortBy } from 'utils/standard';

import { EditorLeftColumn } from '../EditorLeftColumn';

import { DatasetConfig } from './DatasetConfig';
import * as styles from './DatasetEditor.css';
import { useColorCategoryTracker } from './useColorCategoryTracker';

type Props = {
  config: ReportBuilderConfig;
  reportBuilderId: number;
};

type SelectedDatasetInfo = { id: string; isNew: boolean };

export const DatasetEditor: FC<Props> = ({ config, reportBuilderId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const [searchQuery, setSearchQuery] = useState('');

  const [selectedDatasetInfo, setSelectedDatasetInfo] = useState<SelectedDatasetInfo | null>(null);

  const selectedDataset = selectedDatasetInfo ? config.datasets[selectedDatasetInfo.id] : undefined;
  const { datasetData, globalStyleConfig, shouldUseFido, schemas } = useSelector(
    (state: ReduxState) => ({
      datasetData: selectedDatasetInfo
        ? state.reportBuilderEdit.datasetData[selectedDatasetInfo.id]
        : undefined,
      globalStyleConfig: state.embeddedReportBuilder.styleConfig,
      shouldUseFido: state.currentUser.team?.feature_flags.use_fido,
      schemas: state.parentSchemas,
    }),
    shallowEqual,
  );

  const colorCategoryTracker = useColorCategoryTracker(
    globalStyleConfig,
    selectedDataset?.columnConfigs,
    datasetData?.rows,
  );

  const filteredAndOrderedDatasets = useMemo(
    () =>
      sortBy(
        filterReportBuilderDatasets(searchQuery, Object.values(config.datasets)),
        (dataset) => dataset.name,
      ),
    [config.datasets, searchQuery],
  );

  const switchSelectedDataset = useCallback(
    (datasetId: string, isNew?: boolean) => {
      dispatch(fetchAppDataset({ datasetId, switchedToDataset: true }));

      history.replace(`/report-builder/${reportBuilderId}/datasets?id=${datasetId}`);
      setSelectedDatasetInfo({ id: datasetId, isNew: isNew ?? false });
    },
    [dispatch, history, reportBuilderId],
  );

  useEffect(() => {
    if (selectedDatasetInfo || filteredAndOrderedDatasets.length === 0) return;

    const id = query.get('id');
    const dataset = config.datasets[id ?? ''] ?? filteredAndOrderedDatasets[0];
    switchSelectedDataset(dataset.id);
  }, [
    query,
    config.datasets,
    switchSelectedDataset,
    selectedDatasetInfo,
    filteredAndOrderedDatasets,
  ]);

  useEffect(() => {
    if (!selectedDatasetInfo || !datasetData?.duplicateColumns) return;
    dispatch(clearDuplicateColumns(selectedDatasetInfo.id));
    showDuplicateColumnNameToast(datasetData.duplicateColumns, true);
  }, [dispatch, datasetData?.duplicateColumns, selectedDatasetInfo]);

  const schema = useMemo(
    () => selectedDataset && getReportBuilderSchema(datasetData?.schema, selectedDataset, true),
    [datasetData?.schema, selectedDataset],
  );

  const handleAddDataset = useCallback(
    (name: string, parentSchemaId: number) => {
      const newId = uuidv4();

      if (shouldUseFido) {
        dispatch(
          createComputedView({
            name,
            namespace: RD.getOrDefault(schemas.usedParentSchemas, []).find(
              (s) => s.id === parentSchemaId,
            ),
            resourceType: ResourcePageType.REPORT_BUILDER,
            datasetId: newId,
            onSuccess: () => switchSelectedDataset(newId, true),
          }),
        );
      } else {
        dispatch(createReportBuilderDataset({ id: newId, name, parentSchemaId }));
        switchSelectedDataset(newId, true);
      }
    },
    [dispatch, schemas.usedParentSchemas, shouldUseFido, switchSelectedDataset],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      return (
        selectedDataset &&
        dispatch(
          fetchAppDataset({
            datasetId: selectedDataset.id,
            page,
          }),
        )
      );
    },
    [dispatch, selectedDataset],
  );

  const noDatasetSelectedDiv = () => {
    return (
      <div className={styles.emptyContainer} style={{ fontSize: 20 }}>
        Select a Dataset
      </div>
    );
  };

  const renderedContent = useMemo(
    () =>
      filteredAndOrderedDatasets.length > 0 ? (
        filteredAndOrderedDatasets.map((dataset) => {
          const isSelected = dataset.id === selectedDatasetInfo?.id;
          return (
            <DatasetItem
              dataset={dataset}
              isSelected={isSelected}
              key={dataset.id}
              onClick={() => {
                if (isSelected) return;
                switchSelectedDataset(dataset.id);
              }}
            />
          );
        })
      ) : (
        <EmbedText
          body="b1"
          className={sprinkles({ parentContainer: 'fill', flexItems: 'centerColumn' })}
          color="contentSecondary">
          No datasets.
        </EmbedText>
      ),
    [filteredAndOrderedDatasets, selectedDatasetInfo?.id, switchSelectedDataset],
  );

  return (
    <div className={styles.root}>
      <EditorLeftColumn>
        <div
          className={cx(styles.datasetListContainer, {
            [sprinkles({ height: 'fill' })]: !filteredAndOrderedDatasets.length,
          })}>
          <AddDatasetButton datasets={filteredAndOrderedDatasets} onSubmit={handleAddDataset} />
          <SearchInput
            className={sprinkles({ paddingX: 'sp1' })}
            onInputChanged={setSearchQuery}
            searchQuery={searchQuery}
          />
          {renderedContent}
        </div>
      </EditorLeftColumn>
      <div className={styles.configMenu} style={{ width: 545, minWidth: 545 }}>
        {selectedDataset ? (
          <DatasetConfig dataset={selectedDataset} isNew={selectedDatasetInfo?.isNew ?? false} />
        ) : (
          noDatasetSelectedDiv()
        )}
      </div>
      <div className={styles.tableView}>
        {selectedDataset ? (
          <>
            <ColumnHeader title="Preview" />
            <DatasetPreviewTable
              colorTracker={colorCategoryTracker}
              currentQuery={selectedDataset.queryDraft ?? selectedDataset.query}
              dataset={selectedDataset}
              error={datasetData?.error}
              handlePageChange={handlePageChange}
              isLoading={!!datasetData?.loading}
              rowCount={datasetData?.rowCount}
              rows={datasetData?.rows}
              schema={schema}
              unsupportedOperations={datasetData?.unsupportedOperations}
            />
          </>
        ) : (
          noDatasetSelectedDiv()
        )}
      </div>
    </div>
  );
};
