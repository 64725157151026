import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { Dashboard } from 'actions/dashboardActions';
import { fetchDashboardAttributes } from 'actions/dashboardAttributesActions';
import {
  createNewDashboardVersion,
  previewVersion,
  switchCurrentlyEditingDashboardVersion,
} from 'actions/dashboardV2Actions';
import { fetchEmailCadenceList } from 'actions/emailActions';
import { ACTION } from 'actions/types';
import { VersionControlModal } from 'components/VersionControlModal';
import { ViewingAsCustomerSelector } from 'components/ViewingAsCustomerSelector';
import { Button, IconButton, NavigationToggle, sprinkles } from 'components/ds';
import { NavigationItem } from 'components/ds/NavigationToggle';
import { PERMISSIONED_ACTIONS, PERMISSIONED_ENTITIES } from 'constants/roleConstants';
import { ROUTE_PROVIDERS, ROUTES } from 'constants/routes';
import { createLoadingSelector } from 'reducers/api/selectors';
import {
  setExploreInteractionsInfo,
  toggleVisiblePanes,
} from 'reducers/dashboardInteractionsReducer';
import { ReduxState } from 'reducers/rootReducer';
import { getCurrentDashboard } from 'reducers/selectors';
import { fetchDashboardDataThunk } from 'reducers/thunks/dashboardDataThunks/requestLogicThunks';
import { cloneComputedViews } from 'reducers/thunks/fidoThunks';
import { fetchVersion } from 'reducers/thunks/versionManagementThunks';
import * as RD from 'remotedata';
import { EditResourceBannerDropdown } from 'shared/ExploResource/EditResourceBannerDropdown';
import { SIDE_PANE_HEADER_HEIGHT } from 'shared/ExploResource/constants';
import { VIEW_MODE } from 'types/dashboardTypes';
import { DashboardVersionConfig } from 'types/dashboardVersionConfig';
import { ResourcePageType, VersionInfo } from 'types/exploResource';
import { isEmailReportingEnabled } from 'utils/paymentPlanUtils';
import { canUserModifyResource, doesUserHavePermission } from 'utils/permissionUtils';

import { SavingInfo } from './SavingInfo';

type Props = {
  dashboardVersionInfo: VersionInfo;
  dashboard: Dashboard;
  inEditMode?: boolean;
};

export const DashboardEditBanner: FC<Props> = ({ dashboard, dashboardVersionInfo, inEditMode }) => {
  const dispatch = useDispatch();
  const {
    viewMode,
    mostRecentVersion,
    currentDashboardId,
    dashboardAttributes,
    currentUser,
    switchEditModeLoading,
    emailCadences,
    paneStates,
    useFido,
    breadcrumbs,
    dashboardHierarchy,
  } = useSelector(
    (state: ReduxState) => ({
      viewMode: state.dashboardInteractions.interactionsInfo.viewMode,
      mostRecentVersion: RD.getOrDefault(state.dashboardVersions.versionsMetadata, [])?.[0],
      currentDashboardId: getCurrentDashboard(state)?.id,
      dashboardAttributes: state.dashboardAttributes.attributes,
      currentUser: state.currentUser,
      paneStates: state.dashboardInteractions.paneOpenStates,
      emailCadences: state.emailCadence.cadences,
      switchEditModeLoading: createLoadingSelector(
        [ACTION.CREATE_NEW_DASHBOARD_VERSION, ACTION.PUBLISH_NEW_DASHBOARD_VERSION],
        false,
      )(state),
      useFido: state.currentUser.team?.feature_flags.use_fido,
      breadcrumbs: getCurrentDashboard(state)?.breadcrumbs,
      dashboardHierarchy: state.dashboard.dashboardHierarchy,
    }),
    shallowEqual,
  );
  const [versionControlModalOpen, setVersionControlModalOpen] = useState(false);

  useEffect(() => {
    if (
      RD.isIdle(dashboardAttributes) &&
      canUserModifyResource(currentUser.permissions[PERMISSIONED_ENTITIES.DASHBOARD])
    )
      dispatch(fetchDashboardAttributes());
  }, [dashboardAttributes, currentUser.permissions, dispatch]);

  useEffect(() => {
    if (!currentUser.team?.id || !RD.isIdle(emailCadences)) return;
    dispatch(fetchEmailCadenceList({ id: currentUser.team.id }));
  }, [emailCadences, dispatch, currentUser.team?.id]);

  const emailCadence = useMemo(
    () =>
      RD.getOrDefault(emailCadences, []).find(
        (cadence) => cadence.dashboard_template_id === dashboard.id,
      ),
    [emailCadences, dashboard.id],
  );

  const userCanEditDashboard = useMemo(
    () =>
      doesUserHavePermission(
        currentUser.permissions[PERMISSIONED_ENTITIES.DASHBOARD],
        PERMISSIONED_ACTIONS.UPDATE,
      ),
    [currentUser.permissions],
  );

  const onEditClicked = useCallback(() => {
    if (currentDashboardId === undefined) return;

    dispatch(setExploreInteractionsInfo({ isEditing: true }));
    // If our current version is a draft, no config change needed
    if (dashboardVersionInfo.is_draft) return;

    // Case switch to most recent draft if exists
    if (mostRecentVersion?.is_draft) {
      dispatch(
        previewVersion(
          {
            id: currentDashboardId,
            queryParams: { version_number: mostRecentVersion.version_number },
          },
          ({ preview_version }) =>
            dispatch(switchCurrentlyEditingDashboardVersion({ dashboardVersion: preview_version })),
        ),
      );
    } else {
      // Case create a new version if no most recent draft
      if (!useFido) {
        dispatch(
          createNewDashboardVersion(
            { id: currentDashboardId, postData: {} },
            ({ dashboard_version }) =>
              dispatch(
                switchCurrentlyEditingDashboardVersion({
                  dashboardVersion: dashboard_version,
                }),
              ),
          ),
        );
      } else {
        if (!mostRecentVersion) return;

        dispatch(
          fetchVersion({
            isExplore: true,
            args: { id: currentDashboardId },
            onSuccess: ({ version }) => {
              if (!version || !('configuration' in version)) return;

              return dispatch(
                cloneComputedViews({
                  configuration: version.configuration,
                  onSuccess: (configuration) =>
                    dispatch(
                      createNewDashboardVersion(
                        {
                          id: currentDashboardId,
                          postData: { configuration: configuration as DashboardVersionConfig },
                        },
                        ({ dashboard_version }) =>
                          dispatch(
                            switchCurrentlyEditingDashboardVersion({
                              dashboardVersion: dashboard_version,
                            }),
                          ),
                      ),
                    ),
                }),
              );
            },
          }),
        );
      }
    }
  }, [dispatch, dashboardVersionInfo, currentDashboardId, mostRecentVersion, useFido]);

  const onReturnMostCurrentVersionClicked = useCallback(() => {
    if (currentDashboardId === undefined || !mostRecentVersion) return;
    dispatch(
      previewVersion(
        {
          id: currentDashboardId,
          queryParams: { version_number: mostRecentVersion.version_number },
        },
        ({ preview_version }) =>
          dispatch(switchCurrentlyEditingDashboardVersion({ dashboardVersion: preview_version })),
      ),
    );
  }, [dispatch, currentDashboardId, mostRecentVersion]);

  const setViewMode = (viewMode: VIEW_MODE) => dispatch(setExploreInteractionsInfo({ viewMode }));

  const renderViewModeButtonGroup = () => {
    const value = viewMode;
    const items: NavigationItem[] = [
      { iconName: 'desktop', value: VIEW_MODE.DEFAULT, tooltipText: 'Desktop' },
      { iconName: 'mobile-screen', value: VIEW_MODE.MOBILE, tooltipText: 'Mobile' },
      { iconName: 'file-pdf', value: VIEW_MODE.PDF, tooltipText: 'PDF' },
    ];
    if (isEmailReportingEnabled(currentUser)) {
      items.push({ iconName: 'envelope', value: VIEW_MODE.EMAIL, tooltipText: 'Email' });
    }

    return (
      <NavigationToggle
        valueRequired
        items={items}
        onValueChange={(value) => setViewMode((value as VIEW_MODE) ?? VIEW_MODE.DEFAULT)}
        type="single"
        value={value}
      />
    );
  };

  const renderEditorLayoutButtonsGroup = () => {
    if (!inEditMode || viewMode !== VIEW_MODE.DEFAULT) return;

    const value = [];
    if (paneStates.left) value.push('left');
    if (paneStates.right) value.push('right');

    return (
      <NavigationToggle
        className={sprinkles({ marginRight: 'sp3' })}
        items={[
          {
            iconName: 'sidebar',
            value: 'left',
            tooltipText: `${paneStates.left ? 'Close' : 'Open'} Panel`,
          },
          {
            iconName: 'sidebar-flip',
            value: 'right',
            tooltipText: `${paneStates.right ? 'Close' : 'Open'} Panel`,
          },
        ]}
        onValueChange={(value) => dispatch(toggleVisiblePanes(value))}
        type="multiple"
        value={value}
      />
    );
  };

  const renderEditButtons = () => {
    return (
      <div className={sprinkles({ display: 'flex', alignItems: 'center', marginLeft: 'sp1' })}>
        <SavingInfo
          isDraft={dashboardVersionInfo.is_draft}
          resourceType={ResourcePageType.EXPLORE}
          versionNumber={dashboardVersionInfo.version_number}
        />
        <div className={sprinkles({ display: 'flex', gap: 'sp1' })}>
          {inEditMode ? (
            <>
              <IconButton
                name="refresh"
                onClick={() => dispatch(fetchDashboardDataThunk({ shouldOverrideCache: true }))}
                tooltipProps={{
                  text:
                    'Refresh Dashboard' +
                    (currentUser.team?.entitlements.enable_cache && dashboard.is_cache_enabled
                      ? '. This will also refresh the cache.'
                      : ''),
                }}
                variant="primary"
              />
              <Button
                disabled={switchEditModeLoading}
                loading={switchEditModeLoading}
                onClick={() => dispatch(setExploreInteractionsInfo({ isEditing: false }))}
                variant="primary">
                Done Editing
              </Button>
            </>
          ) : (
            renderEditDashboardButton()
          )}
        </div>
      </div>
    );
  };

  const renderEditDashboardButton = () => {
    // There should always be at least 1 dashboard version, so if most mostRecentVersion doesn't exist it means we are still waiting for versions to load
    const versionsHaveLoaded = !!mostRecentVersion;

    if (
      versionsHaveLoaded &&
      dashboardVersionInfo.version_number !== mostRecentVersion?.version_number
    ) {
      return (
        <>
          <Button
            className={sprinkles({ marginRight: 'sp1' })}
            disabled={switchEditModeLoading}
            onClick={() => setVersionControlModalOpen(true)}
            variant="secondary">
            Version Control
          </Button>
          <Button
            disabled={switchEditModeLoading}
            loading={switchEditModeLoading}
            onClick={onReturnMostCurrentVersionClicked}
            variant="primary">
            Return to Current Draft
          </Button>
        </>
      );
    }

    return (
      <>
        <Button
          className={sprinkles({ marginRight: 'sp1' })}
          disabled={switchEditModeLoading}
          onClick={() => !switchEditModeLoading && setVersionControlModalOpen(true)}
          variant="secondary">
          Version Control
        </Button>
        {userCanEditDashboard ? (
          <Button
            disabled={switchEditModeLoading || !versionsHaveLoaded}
            loading={switchEditModeLoading}
            onClick={() => !switchEditModeLoading && onEditClicked()}
            variant="primary">
            Edit
          </Button>
        ) : null}
      </>
    );
  };

  const renderVersionControlModal = () => {
    if (!versionControlModalOpen || currentDashboardId === undefined) return;

    return (
      <VersionControlModal
        closeModal={() => setVersionControlModalOpen(false)}
        pageType={ResourcePageType.EXPLORE}
      />
    );
  };

  const onDeletionUrlProviderFn = useCallback(() => {
    if (!RD.isSuccess(dashboardHierarchy) || !currentDashboardId) {
      return ROUTES.HOME_APP_PAGE;
    }

    const dashboard = dashboardHierarchy.data.dashboards[currentDashboardId];

    if (!dashboard.parentDashboardId) {
      return ROUTES.HOME_APP_PAGE;
    }

    return ROUTE_PROVIDERS.DASHBOARD(`${dashboard.parentDashboardId}`);
  }, [dashboardHierarchy, currentDashboardId]);

  return (
    <div
      className={sprinkles({
        flexItems: 'alignCenterBetween',
        backgroundColor: 'white',
        borderBottom: 1,
        borderBottomColor: 'gray7',
        paddingRight: 'sp1.5',
        width: 'fill',
      })}
      style={{ height: SIDE_PANE_HEADER_HEIGHT }}>
      <EditResourceBannerDropdown
        breadcrumbs={breadcrumbs ?? []}
        dashboardAttributes={RD.getOrDefault(dashboardAttributes, [])}
        emailCadence={emailCadence}
        onDeletionUrlProviderFn={onDeletionUrlProviderFn}
        pageType={ResourcePageType.EXPLORE}
        resource={dashboard}
      />
      <div className={sprinkles({ display: 'flex', alignItems: 'center', marginLeft: 'sp2' })}>
        <div className={sprinkles({ marginRight: 'sp.5', width: 'maxContent' })}>Viewing as</div>
        <ViewingAsCustomerSelector parseUrl />
      </div>
      <div
        className={sprinkles({ display: 'flex', flex: 1, justifyContent: 'center', minWidth: 0 })}>
        {renderEditorLayoutButtonsGroup()}
        {renderViewModeButtonGroup()}
      </div>
      <div
        className={sprinkles({
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-end',
          minWidth: 0,
        })}>
        {renderEditButtons()}
      </div>
      {renderVersionControlModal()}
    </div>
  );
};
