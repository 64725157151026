import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { Tag } from 'components/ds';
import { ReduxState } from 'reducers/rootReducer';
import { LatestVersionInfo, Resource } from 'types/exploResource';
import { getPreviewInfoFromLatestVersion } from 'utils/exploResourceUtils';
import { maxBy } from 'utils/standard';
import { getTimezoneAwareDate } from 'utils/timezoneUtils';

import { ResourceItemCard } from './ResourceItemCard';
import { ResourceItemListElement } from './ResourceItemListElement';
import { ResourcePreview } from './ResourcePreview';
import * as styles from './styles.css';

type Props = {
  isCard: boolean;
  resource: Resource;
  showPreview?: boolean;
  dotsMenu: JSX.Element | null;
  hasEmailState?: boolean;
  onClickUrl: string;
  isLoading?: boolean;
};

export const ResourceItem: FC<Props> = ({
  isCard,
  resource,
  showPreview,
  dotsMenu,
  hasEmailState,
  onClickUrl,
  isLoading,
}) => {
  const globalStyleConfig = useSelector(
    (state: ReduxState) => state.dashboardStyles.globalStyleConfig,
  );

  const lastModified = maxBy(resource.latest_versions, (info) => info.version_number)?.modified;
  const modifiedText = lastModified
    ? `Last edited ${getTimezoneAwareDate(lastModified).toRelative()}`
    : '';

  const isDisabled = 'disabled' in resource ? resource.disabled ?? false : false;

  const latestConfig = resource.latest_versions[resource.latest_versions.length - 1];

  const browserSupportsZoom = 'zoom' in document.body.style;

  const renderEmailTag = useCallback(() => {
    return (
      <Tag intent="active" leftIcon="envelope-reg">
        Email enabled
      </Tag>
    );
  }, []);

  const renderLatestVersionTag = useCallback(
    (latest: Pick<LatestVersionInfo, 'is_draft' | 'version_number'>) => (
      <Tag key={`${latest.version_number}`}>
        {latest.is_draft ? 'In-progress draft' : `Version ${latest.version_number}`}
      </Tag>
    ),
    [],
  );

  const renderPreview = useCallback(
    (latestVersion: LatestVersionInfo | undefined) => {
      const previewInfo = latestVersion
        ? getPreviewInfoFromLatestVersion(latestVersion)
        : undefined;

      if (!previewInfo) {
        return <div className={styles.noPreview}>Error</div>;
      }

      if (!previewInfo.layout.length && previewInfo.editableSectionTitle == undefined) {
        return <div className={styles.noPreview}>Empty</div>;
      }

      return (
        <ResourcePreview
          dataPanels={previewInfo.dataPanels}
          editableSectionTitle={previewInfo.editableSectionTitle}
          elements={previewInfo.elements}
          globalStyleConfig={globalStyleConfig}
          layout={previewInfo.layout}
          useZoom={browserSupportsZoom && !isCard}
        />
      );
    },
    [browserSupportsZoom, globalStyleConfig, isCard],
  );

  return isCard ? (
    <ResourceItemCard
      dotsMenu={dotsMenu}
      hasEmailState={hasEmailState || false}
      isDisabled={isDisabled}
      isLoading={isLoading || false}
      lastModifiedMessage={modifiedText}
      latestConfig={latestConfig as LatestVersionInfo}
      onClickUrl={onClickUrl}
      renderEmailTagFn={renderEmailTag}
      renderLatestVersionTagFn={renderLatestVersionTag}
      renderPreviewFn={renderPreview}
      resource={resource}
    />
  ) : (
    <ResourceItemListElement
      dotsMenu={dotsMenu}
      hasEmailState={hasEmailState || false}
      isDisabled={isDisabled}
      isLoading={isLoading || false}
      lastModifiedMessage={modifiedText}
      latestConfig={latestConfig as LatestVersionInfo}
      onClickUrl={onClickUrl}
      renderEmailTagFn={renderEmailTag}
      renderLatestVersionTagFn={renderLatestVersionTag}
      renderResourcePreviewFn={renderPreview}
      resource={resource}
      showPreview={showPreview || false}
    />
  );
};
