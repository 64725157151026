import { DateTime } from 'luxon';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { previewVersion, switchCurrentlyEditingDashboardVersion } from 'actions/dashboardV2Actions';
import { EnvironmentTag } from 'actions/environmentTagActions';
import { ReportBuilderVersionMetadata } from 'actions/reportBuilderVersionActions';
import { ACTION } from 'actions/types';
import { TeamMember } from 'actions/userActions';
import {
  AlertModal,
  APP_PORTAL_ID,
  IconButton,
  Intent,
  Menu,
  MenuActionItem,
  MenuSub,
  sprinkles,
  Tag,
} from 'components/ds';
import { PERMISSIONED_ACTIONS } from 'constants/roleConstants';
import { createLoadingSelector } from 'reducers/api/selectors';
import { ReduxState } from 'reducers/rootReducer';
import { cloneResource, revertResourceToVersion } from 'reducers/thunks/versionManagementThunks';
import * as RD from 'remotedata';
import { DashboardVersionMetadata } from 'types/dashboardVersion';
import { ResourcePageType } from 'types/exploResource';
import { getCloneResourceSuccessUrl, isVersionTagged } from 'utils/exploResourceUtils';
import { doesUserHavePermission } from 'utils/permissionUtils';
import { getTimezoneAwareDate } from 'utils/timezoneUtils';

import { PublishToDropdown } from './PublishToDropdown';
import * as styles from './index.css';

type Props = {
  canRevert: boolean;
  closeModal: () => void;
  resourceId: number;
  isDraft: boolean;
  pageType: ResourcePageType;
  tags: RD.ResponseData<EnvironmentTag[]>;
  version: DashboardVersionMetadata | ReportBuilderVersionMetadata;
  previousVersion?: DashboardVersionMetadata | ReportBuilderVersionMetadata;
  userPermissions: string[];
  publisher: TeamMember | undefined;
  onSelectComparisonVersions: (currentVersionNumber: number) => void;
};

enum VersionModalStatus {
  NO_OPEN_MODAL = 'No Open Modal',
  REVERT = 'Revert',
  DUPLICATE = 'Duplicate',
}

export const VersionPanel: FC<Props> = ({
  canRevert,
  closeModal,
  resourceId,
  isDraft,
  pageType,
  publisher,
  tags,
  version,
  previousVersion,
  userPermissions,
  onSelectComparisonVersions,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState<VersionModalStatus>(VersionModalStatus.NO_OPEN_MODAL);

  const isExploreProduct = pageType === ResourcePageType.EXPLORE;

  const revertAction = isExploreProduct
    ? ACTION.REVERT_TO_DASHBOARD_VERSION
    : ACTION.REVERT_TO_REPORT_BUILDER_VERSION;

  const revertLoading = useSelector((state: ReduxState) =>
    createLoadingSelector([revertAction], false)(state),
  );

  const isDeprecated = version.deprecated;
  const envTags = RD.isSuccess(tags) ? tags.data : undefined;

  const userCanEditResource = doesUserHavePermission(userPermissions, PERMISSIONED_ACTIONS.UPDATE);

  const userCanPublishResource = doesUserHavePermission(
    userPermissions,
    PERMISSIONED_ACTIONS.PUBLISH,
  );
  const userCanDuplicateResource = doesUserHavePermission(
    userPermissions,
    PERMISSIONED_ACTIONS.CREATE,
  );

  const FORMER_TEAMMATE_TEXT = 'Former Teammate';

  const handlePreview = () => {
    if (isExploreProduct) {
      dispatch(
        previewVersion(
          {
            id: resourceId,
            queryParams: { version_number: version.version_number },
          },
          ({ preview_version }) =>
            dispatch(switchCurrentlyEditingDashboardVersion({ dashboardVersion: preview_version })),
        ),
      );
    }
    closeModal();
  };

  const handleRevert = () =>
    dispatch(
      revertResourceToVersion(
        isExploreProduct,
        {
          id: resourceId,
          version_number: version.version_number,
        },
        () => closeModal(),
      ),
    );

  const renderRevertModal = () => (
    <AlertModal
      actionButtonProps={{
        onClick: handleRevert,
        loading: revertLoading,
        text: `Yes, revert to Version ${version.version_number}`,
      }}
      cancelButtonProps={{
        onClick: () => setModalOpen(VersionModalStatus.NO_OPEN_MODAL),
        text: 'Cancel',
      }}
      isOpen={modalOpen === VersionModalStatus.REVERT}
      onClose={() => setModalOpen(VersionModalStatus.NO_OPEN_MODAL)}
      portalContainerId={APP_PORTAL_ID}
      title={`Are you sure you want to revert to Version ${version.version_number}?`}>
      <div className={sprinkles({ paddingX: 'sp3' })}>
        {isDraft
          ? `This will discard your active draft and replace it with another draft matching version ${version.version_number}. Do you want to continue?`
          : `This will add a new draft matching version ${version.version_number}. Do you want to continue?`}
      </div>
    </AlertModal>
  );

  const renderDuplicateModal = () => (
    <AlertModal
      actionButtonProps={{
        text: 'Duplicate',
        onClick: () =>
          dispatch(
            cloneResource(
              isExploreProduct,
              { id: resourceId, version_number: version.version_number },
              (data) =>
                history.push(getCloneResourceSuccessUrl(isExploreProduct, data.new_resource.id)),
            ),
          ),
        variant: 'primary',
      }}
      isOpen={modalOpen === VersionModalStatus.DUPLICATE}
      onClose={() => setModalOpen(VersionModalStatus.NO_OPEN_MODAL)}
      portalContainerId={APP_PORTAL_ID}
      title={`Duplicate version ${version.version_number} to a new ${
        isExploreProduct ? 'dashboard' : 'report builder'
      }?`}
    />
  );

  // Returns a message that describes when and who (if available) published and created the version.
  const getPublishDetailMessage = () => {
    const baseDate = getTimezoneAwareDate(version.version_saved_at || version.created)
      // set the locale to english for this specific instance because
      // we don't want the app builder itself to be translated yet
      .setLocale('en-us');

    const publisherName = publisher
      ? `${publisher.first_name} ${publisher.last_name}`
      : FORMER_TEAMMATE_TEXT;

    return `${publisherName} on ${baseDate.toLocaleString(DateTime.DATE_FULL)}`;
  };

  return (
    <div className={styles.versionPanelRoot}>
      <div className={sprinkles({ width: 'fill' })}>
        <div className={styles.versionPanelNameAndTags}>
          <div className={sprinkles({ whiteSpace: 'nowrap', marginRight: 'sp1' })}>
            Version {version.version_number}
          </div>
          <Tag className={sprinkles({ marginRight: 'sp1' })}>{getPublishDetailMessage()}</Tag>
          {isDeprecated ? (
            <Tag
              inverted
              className={sprinkles({ marginRight: 'sp1' })}
              intent={Intent.ERROR}
              key={`deprecated-${version.id}`}>
              Deprecated
            </Tag>
          ) : null}
          {envTags?.map((tag) =>
            isVersionTagged(pageType, resourceId, version.id, tag) ? (
              <Tag
                inverted
                backgroundColor={tag.color_hex}
                className={sprinkles({ marginRight: 'sp1' })}
                key={`attached-tag-${tag.id}`}>
                {tag.name}
              </Tag>
            ) : null,
          )}
        </div>
        <div className={styles.changeComment}>{version.change_comments}</div>
      </div>
      {!isDeprecated ? (
        <div className={sprinkles({ flexItems: 'alignCenter' })}>
          <Menu align="end" trigger={<IconButton name="ellipsis-vertical" />} width="small">
            {isExploreProduct ? <MenuActionItem onSelect={handlePreview} text="Preview" /> : null}
            {previousVersion ? (
              <MenuActionItem
                onSelect={() => onSelectComparisonVersions(version.version_number)}
                text="Compare to Previous Version"
              />
            ) : null}
            {canRevert && userCanEditResource ? (
              <MenuActionItem
                onSelect={() => setModalOpen(VersionModalStatus.REVERT)}
                text="Revert"
              />
            ) : null}
            {userCanDuplicateResource ? (
              <MenuActionItem
                onSelect={() => setModalOpen(VersionModalStatus.DUPLICATE)}
                text="Duplicate"
              />
            ) : null}
            {userCanPublishResource && envTags ? (
              <MenuSub trigger="Publish To" width="small">
                <PublishToDropdown
                  pageType={pageType}
                  resourceId={resourceId}
                  tags={envTags}
                  versionId={version.id}
                />
              </MenuSub>
            ) : null}
          </Menu>
          {renderRevertModal()}
          {renderDuplicateModal()}
        </div>
      ) : null}
    </div>
  );
};
