import { FC, useEffect, useMemo, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { containerClassName } from 'components/ChartLayout/ChartLayout.css';
import { DashboardLayoutPoller } from 'components/DashboardLayout/DashboardLayoutPoller';
import { DataPanelWrapper } from 'components/DashboardLayout/DataPanelWrapper';
import { Spinner } from 'components/ds';
import { DASHBOARD_LOADED_CLASS_NAME } from 'constants/exportConstants';
import { OPERATION_TYPES, UserTransformedSchema } from 'constants/types';
import { EmbedReduxState } from 'embeddedContent/reducers/rootReducer';
import {
  isDataPanelLoading,
  setDashboardVariables,
  updateAdHocOperationInstructions,
} from 'reducers/dashboardDataReducer';
import { setRequestInfo } from 'reducers/dashboardLayoutReducer';
import {
  getEditableSectionLayout,
  getEmbedDashboardConfigSelector,
  getEmbeddedDashboardVersionWithDrilldowns,
  getEmbeddedDashboardWithDrilldowns,
  getEmbeddedRootDashboardVersion,
} from 'reducers/selectors';
import { initializeDashboardDataThunk } from 'reducers/thunks/dashboardDataThunks/requestLogicThunks';
import * as RD from 'remotedata';
import { DashboardVariableMap, PAGE_TYPE } from 'types/dashboardTypes';
import { AdHocOperationInstructions } from 'types/dataPanelTemplate';
import { getDashboardTimezone } from 'utils/dashboardUtils';
import { getDatasetNamesToId } from 'utils/datasetUtils';
import { isChartInstanceOfTemplate } from 'utils/editableSectionUtils';
import { getDataPanelQueryContext } from 'utils/variableUtils';

import { PDFExportTableView } from './PDFExportTableView/PDFExportTableView';

type Props = {
  dashboardEmbedId: string;
  dataPanelId: string;
  variables: DashboardVariableMap;
  userTransformedSchema: UserTransformedSchema;
  adHocOperationInstructions: AdHocOperationInstructions;
  reportName: string;
  customerToken?: string; // Either customerToken or embedJwt must be provided
  embedJwt?: string;
  isScreenshotDownload: boolean;
};

export const ChartLayout: FC<Props> = ({
  adHocOperationInstructions,
  dashboardEmbedId,
  dataPanelId,
  variables: rawVariables,
  reportName,
  userTransformedSchema,
  customerToken,
  embedJwt,
  isScreenshotDownload,
}) => {
  const dispatch = useDispatch();

  const {
    isLoading,
    dataPanelMaxDataPoints,
    shouldUseJobQueue,
    pdfMaxRows,
    dashboard,
    shouldUseFido,
    enableEmailExports,
    enableScreenshotExports,
    dashboardConfig,
    dashboardVersionNumber,
    datasets,
  } = useSelector(
    (state: EmbedReduxState) => ({
      isLoading: isDataPanelLoading(state.dashboardData, dataPanelId),
      dashboardVersionNumber: getEmbeddedDashboardVersionWithDrilldowns(state)?.version_number,
      pdfMaxRows: state.embedDashboard.team?.configuration.pdf_max_rows,
      dataPanelMaxDataPoints: state.embedDashboard.team?.configuration.data_panel_max_data_points,
      shouldUseJobQueue: !!state.embedDashboard.team?.feature_flags.use_job_queue,
      shouldUseFido: !!state.embedDashboard.team?.feature_flags.use_fido,
      enableScreenshotExports: !!state.embedDashboard.team?.entitlements.enable_screenshot_exports,
      enableEmailExports: !!state.embedDashboard.team?.entitlements.enable_email_exports,
      dashboard: getEmbeddedDashboardWithDrilldowns(state),
      dashboardConfig: getEmbedDashboardConfigSelector(state),
      datasets: getEmbeddedRootDashboardVersion(state)?.configuration.datasets,
    }),
    shallowEqual,
  );

  const layout = useSelector(getEditableSectionLayout);
  const hasMountedRef = useRef(false);

  const dp = useMemo(() => {
    const dataPanel = dashboardConfig?.dataPanels[dataPanelId];
    if (dataPanel) return dataPanel;

    // Use the template id if the chart is an editable section chart
    const chart = layout?.find((item) => item.i === dataPanelId);
    if (!chart) return;

    const editableSectionCharts = dashboardConfig?.editableSectionCharts || {};
    const editableSectionChart = Object.values(editableSectionCharts).find((editableSectionChart) =>
      isChartInstanceOfTemplate(chart, editableSectionChart),
    );
    if (editableSectionChart) return editableSectionChart.data_panel;
  }, [dashboardConfig?.dataPanels, dashboardConfig?.editableSectionCharts, dataPanelId, layout]);

  const dashboardTimezone = useMemo(
    () => getDashboardTimezone(RD.getOrDefault(dashboard, undefined)),
    [dashboard],
  );

  useEffect(() => {
    if (!Object.keys(adHocOperationInstructions).length) return;
    dispatch(
      updateAdHocOperationInstructions({
        id: dataPanelId,
        instructions: adHocOperationInstructions,
      }),
    );
  }, [adHocOperationInstructions, dataPanelId, dispatch]);

  useEffect(() => {
    // Job queue increased max PDF rows from 200 to 1000 for tradeoff between load time and # of rows
    // This could be made configurable for the customer in the future
    const numDefaultDataPoints =
      shouldUseJobQueue || shouldUseFido ? JOB_QUEUE_MAX_ROWS : EMBEDDO_MAX_ROWS;

    dispatch(
      setRequestInfo({
        type: 'embedded',
        embedType: 'iframe',
        environment: undefined,
        resourceEmbedId: dashboardEmbedId,
        timezone: dashboardTimezone,
        versionNumber: dashboardVersionNumber || 0,
        useJobQueue: shouldUseJobQueue,
        customerToken: customerToken,
        jwt: embedJwt,
        useFido: shouldUseFido,
        enableScreenshotExports,
        enableEmailExports,
        datasetMaxRows:
          // in a screenshot context, use the team configured pdf override if set
          isScreenshotDownload
            ? pdfMaxRows ?? numDefaultDataPoints
            : // otherwise use the default for the execution engine
              numDefaultDataPoints,
        dataPanelMaxDataPoints:
          // in a screenshot content, use the team configured pdf override if set
          isScreenshotDownload
            ? pdfMaxRows ??
              // otherwise the default for the execution engine
              numDefaultDataPoints
            : // if not in a screenshot context, use the team configured data points override if set
              dataPanelMaxDataPoints,
      }),
    );
  }, [
    customerToken,
    dashboardEmbedId,
    dashboardTimezone,
    dashboardVersionNumber,
    dataPanelMaxDataPoints,
    dispatch,
    embedJwt,
    pdfMaxRows,
    shouldUseFido,
    shouldUseJobQueue,
    isScreenshotDownload,
    enableScreenshotExports,
    enableEmailExports,
  ]);

  const variables = useMemo(
    () => (dp ? getDataPanelQueryContext(dp, rawVariables) : rawVariables),
    [dp, rawVariables],
  );

  useEffect(() => {
    if (variables) dispatch(setDashboardVariables(variables));
  }, [dispatch, variables]);

  useEffect(() => {
    if (hasMountedRef.current || !dashboardConfig) return;
    hasMountedRef.current = true;
    dispatch(initializeDashboardDataThunk([], dashboardConfig.datasets));
  }, [dashboardConfig, dispatch]);

  const datasetNamesToId = useMemo(
    () => (datasets ? getDatasetNamesToId(datasets) : {}),
    [datasets],
  );

  const dashboardElements = useMemo(
    () => Object.values(dashboardConfig?.elements || {}),
    [dashboardConfig?.elements],
  );

  const renderContent = () => {
    if (!dp) {
      return (
        <div className={containerClassName}>
          Data Panel not found. Have you published your current dashboard version?
        </div>
      );
    } else if (isLoading) {
      return <Spinner fillContainer />;
    } else if (
      [OPERATION_TYPES.VISUALIZE_TABLE, OPERATION_TYPES.VISUALIZE_REPORT_BUILDER].includes(
        dp.visualize_op.operation_type,
      )
    ) {
      return (
        <PDFExportTableView
          dataPanelTemplate={dp}
          datasetNamesToId={datasetNamesToId}
          defaultUserTransformedSchema={userTransformedSchema}
          reportTitle={
            reportName || dp.visualize_op.generalFormatOptions?.headerConfig?.title || ''
          }
          variables={variables}
        />
      );
    } else {
      return (
        <div className={containerClassName}>
          <DataPanelWrapper
            isViewOnly
            dashboardElements={dashboardElements}
            dataPanel={dp}
            datasetNamesToId={datasetNamesToId}
            datasets={datasets || {}}
            defaultUserTransformedSchema={userTransformedSchema}
            dpEndsOnRightSide={false}
            isDragging={false}
            isEditing={false}
            isInContainer={false}
            isResizing={false}
            isScreenshotDownload={isScreenshotDownload}
            onSelect={() => null}
            pageType={PAGE_TYPE.SHARED}
            variables={variables}
          />
        </div>
      );
    }
  };

  return (
    <div className={isLoading ? undefined : DASHBOARD_LOADED_CLASS_NAME}>
      <DashboardLayoutPoller />
      {renderContent()}
    </div>
  );
};

const JOB_QUEUE_MAX_ROWS = 700;
const EMBEDDO_MAX_ROWS = 200;
