import { FC, useEffect, ReactNode } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { setTestimonialIndex } from 'actions/onboardingActions';
import { sprinkles, Icon } from 'components/ds';
import { TESTIMONIALS, TESTIMONIAL_COMPANIES, dottedLine } from 'constants/testimonials';
import embedDashboardImg from 'images/app/embedDashboard.png';
import ExploLogo from 'images/branding/text_logo.svg';
import { ReduxState } from 'reducers/rootReducer';

import { TestimonialLayout } from './TestimonialLayout';
import * as styles from './styles.css';

const LOGO_HEIGHT = 24;

// to for internal routes, url or external links, onClick to dispatch actions and/or navigate
type HelpConfigAction =
  | { to: string; url?: never; onClick?: never }
  | { to?: never; url: string; onClick?: never }
  | { to?: never; url?: never; onClick: () => void };
type HelpLinkConfig = { name: string } & HelpConfigAction;

type Props = {
  helpLinks?: HelpLinkConfig[];
  title: ReactNode | string;
  subtitle?: string;
};

const testimonials = Object.values(TESTIMONIAL_COMPANIES);

export const OnboardingFlowPage: FC<Props> = ({ helpLinks, title, subtitle, children }) => {
  const dispatch = useDispatch();
  const testimonialIndex = useSelector((state: ReduxState) => state.onboarding.testimonialIndex);

  useEffect(() => {
    if (testimonialIndex === null) {
      const randomNum = Math.random();

      const newIndex = randomNum > 0.5 ? -1 : Math.floor(randomNum * 2 * testimonials.length);
      dispatch(setTestimonialIndex(newIndex));
    }
  }, [dispatch, testimonialIndex]);

  const renderRightSide = () => {
    if (testimonialIndex === null) return;

    return (
      <div className={styles.rightSide}>
        {dottedLine}
        {testimonialIndex >= 0 ? (
          <TestimonialLayout testimonial={TESTIMONIALS[testimonials[testimonialIndex]]} />
        ) : (
          <div className={styles.demoRoot}>
            <img
              alt="Example Embedded Dashboard"
              src={embedDashboardImg}
              style={{ width: 400, height: 308 }}
            />
            <div className={sprinkles({ flexItems: 'centerColumn', gap: 'sp2' })}>
              <div>
                Not a customer? Check out the capabilities of our Dashboard & Report Builder.
              </div>
              <div className={styles.demoLink}>
                <a
                  href="https://explo.co/request-a-trial"
                  rel="noopener noreferrer"
                  style={{ color: 'white', textDecoration: 'none' }}
                  target="_blank">
                  Get Started with Explo
                </a>
                <Icon className={sprinkles({ paddingLeft: 'sp1' })} name="arrow-right" size="md" />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderLink = (linkConfig: HelpLinkConfig, index: number) => {
    const key = `link-bottom-config-${linkConfig.name}-${index}`;

    if (linkConfig.to) {
      return (
        <Link className={styles.link} key={key} to={linkConfig.to}>
          {linkConfig.name}
        </Link>
      );
    } else if (linkConfig.onClick) {
      return (
        <a className={styles.link} href="#" key={key} onClick={linkConfig.onClick}>
          {linkConfig.name}
        </a>
      );
    } else {
      return (
        <a
          className={styles.link}
          href={linkConfig.url}
          key={key}
          rel="noopener noreferrer"
          target="_blank">
          {linkConfig.name}
        </a>
      );
    }
  };

  const content = (
    <>
      <img alt="explo logo" src={ExploLogo} style={{ height: LOGO_HEIGHT }} />
      <div className={styles.signUpContent} style={{ maxWidth: isMobile ? undefined : 320 }}>
        <div className={sprinkles({ width: 'fill', marginBottom: 'sp3' })}>
          <div className={sprinkles({ heading: 'h2', color: 'black' })}>{title}</div>
          {subtitle ? (
            <div className={sprinkles({ body: 'b3', color: 'gray11' })}>{subtitle}</div>
          ) : null}
        </div>
        {children}
      </div>
      <div className={styles.helpLinksSection}>{helpLinks?.map(renderLink)}</div>
    </>
  );

  return isMobile ? (
    <div className={styles.mobileRoot}>{content} </div>
  ) : (
    <div className={sprinkles({ height: 'fillViewport', flexItems: 'center' })}>
      <div className={styles.leftSide}>{content}</div>
      {renderRightSide()}
    </div>
  );
};
