import { Dispatch } from 'redux';

import { Dataset } from 'actions/datasetActions';
import { setSelectedDatasetId } from 'reducers/dashboardEditConfigReducer';
import {
  PanelTab,
  setIsDrillDownPreview,
  setPanelTab,
} from 'reducers/dashboardInteractionsReducer';
import { DashboardElement } from 'types/dashboardTypes';
import { DataPanelTemplate } from 'types/dataPanelTemplate';
import { getDataPanelsUsingDataset, getElementsUsingDataset } from 'utils/datasetUtils';
/*
 * Used to get the number of charts and dashboard elements each dataset is used in, and then
 * to convert to the descriptive string displayed with a dataset title
 */
export const getDataPanelUsageTextMap = (
  datasetConfigs: Record<string, Dataset>,
  dataPanels: DataPanelTemplate[],
  elements: DashboardElement[],
): Record<string, string> => {
  if (!datasetConfigs) return {};
  const usageTextMap: Record<string, string> = {};
  Object.keys(datasetConfigs).forEach((datasetConfigId) => {
    const dataPanelsInUse = getDataPanelsUsingDataset(dataPanels, datasetConfigId);
    const elementsInUse = getElementsUsingDataset(elements, datasetConfigId);
    const dataPanelCount = dataPanelsInUse.length;
    const elementCount = elementsInUse.length;
    let usageText = '';
    if (elementCount) usageText += `${elementCount} filter${elementCount > 1 ? 's' : ''}`;
    if (elementCount && dataPanelCount) usageText += ', ';
    if (dataPanelCount) usageText += `${dataPanelCount} chart${dataPanelCount > 1 ? 's' : ''}`;
    usageTextMap[datasetConfigId] = usageText;
  });
  return usageTextMap;
};

export const returnToDatasetsPage = (dispatch: Dispatch) => {
  dispatch(setSelectedDatasetId(null));
  dispatch(setIsDrillDownPreview(false));
  // Since we don't always reach the dataset editor from the datasets panel,
  // we force this button to always return to the datasets panel tab
  dispatch(setPanelTab(PanelTab.DATASETS));
};
