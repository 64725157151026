import { FC, useMemo } from 'react';

import { ConfigSection } from 'components/PanelComponents/ConfigSection';
import { sprinkles } from 'components/ds';
import { DataPanelTemplate, DrilldownEntryPointInfo } from 'types/dataPanelTemplate';
import { areColumnsSubsets, getDataPanelBreakdownColumns } from 'utils/dashboardDrilldownUtils';

import { DashboardDrilldownEntryDetailSection } from './DashboardDrilldownEntryDetailSection';

interface Props {
  dashboardIdToNameMap: Record<number, string>;
  drilldownEntryPointMap: Record<string, DrilldownEntryPointInfo>;
  selectedDataPanel: DataPanelTemplate;
}

export const DashboardDrilldownsSection: FC<Props> = ({
  dashboardIdToNameMap,
  drilldownEntryPointMap,
  selectedDataPanel,
}) => {
  const dashboardIds = useMemo(
    () => new Set(Object.keys(dashboardIdToNameMap)),
    [dashboardIdToNameMap],
  );

  const dataPanelBreakdownColumns = useMemo(
    () => getDataPanelBreakdownColumns(selectedDataPanel),
    [selectedDataPanel],
  );

  const validDrilldownEntryPoints = useMemo(() => {
    return Object.entries(drilldownEntryPointMap ?? {}).filter(
      ([, drilldownEntryPoint]) =>
        dashboardIds.has(String(drilldownEntryPoint.destinationDashboardId)) &&
        areColumnsSubsets(drilldownEntryPoint.sourceChartColumns, dataPanelBreakdownColumns),
    );
  }, [dashboardIds, drilldownEntryPointMap, dataPanelBreakdownColumns]);

  const validDrilldownEntryPointIds = useMemo(() => {
    const validDrilldownEntryPointIds = new Set<string>();
    validDrilldownEntryPoints.forEach(([drilldownEntryPointId]) =>
      validDrilldownEntryPointIds.add(drilldownEntryPointId),
    );
    return validDrilldownEntryPointIds;
  }, [validDrilldownEntryPoints]);

  const invalidDrilldownEntryPoints = useMemo(() => {
    return Object.entries(drilldownEntryPointMap ?? {}).filter(
      ([drilldownEntryPointId]) => !validDrilldownEntryPointIds.has(drilldownEntryPointId),
    );
  }, [drilldownEntryPointMap, validDrilldownEntryPointIds]);

  if (validDrilldownEntryPoints.length === 0 && invalidDrilldownEntryPoints.length === 0) {
    // Do not render this section if there are no drilldown entry points.
    return null;
  }

  const hasValidDrilldownEntryPoints = validDrilldownEntryPoints.length > 0;
  const hasInvalidDrilldownEntryPoints = invalidDrilldownEntryPoints.length > 0;

  return (
    <ConfigSection icon="chart-bar" title="Dashboard drilldowns" variant="header1">
      <div className={sprinkles({ marginTop: 'sp1', paddingX: 'sp2' })}>
        {hasValidDrilldownEntryPoints ? (
          <div className={sprinkles({ heading: 'h4' })}>Valid entry points</div>
        ) : null}
        <DashboardDrilldownEntryDetailSection
          dashboardIdToNameMap={dashboardIdToNameMap}
          drilldownEntryPoints={validDrilldownEntryPoints}
          selectedDataPanelId={selectedDataPanel.id}
        />
        {hasInvalidDrilldownEntryPoints ? (
          <div className={sprinkles({ heading: 'h4' })}>Invalid entry points</div>
        ) : null}
        <DashboardDrilldownEntryDetailSection
          dashboardIdToNameMap={dashboardIdToNameMap}
          drilldownEntryPoints={invalidDrilldownEntryPoints}
          selectedDataPanelId={selectedDataPanel.id}
        />
      </div>
    </ConfigSection>
  );
};
