import { ComputedView, TtlEviction, Folder } from './types';

/**
 * @param path The full path to the view. NOTE: this should include the view's name as well for
 * optimal searching (it will in the real API)
 * @returns
 */
export const createComputedView = (
  id: string,
  name: string,
  path: string,
  description: string | null,
  query: string,
  cacheEvictionPolicy: TtlEviction,
): ComputedView => {
  return {
    id,
    name,
    path,
    description,
    columnDefinitions: [],
    query,
    variables: [],
    cacheEvictionPolicy,
    '@type': 'computed-view',
  };
};

export const createFolder = (
  path: string,
  subFolders: Array<Folder>,
  computedViews: Array<ComputedView>,
): Folder => {
  const content: (ComputedView | Folder)[] = [...subFolders, ...computedViews];
  return {
    '@type': 'folder',
    path,
    content,
  };
};
