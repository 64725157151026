import { FC, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { renameDashboard } from 'actions/dashboardActions';
import { renameReportBuilder } from 'actions/reportBuilderActions';
import { APP_PORTAL_ID, Spinner, sprinkles } from 'components/ds';
import { TextFieldModal } from 'components/modals/textFieldModal';
import { getUniqueNames } from 'reducers/folderReducer';
import { ReduxState } from 'reducers/rootReducer';
import { listResourceContentNames, renameFolder } from 'reducers/thunks/resourceThunks';
import * as RD from 'remotedata';
import { EVENTS, trackEvent } from 'telemetry/exploAnalytics';
import { Folder, Resource, ResourceType } from 'types/exploResource';
import { getResourceNameErrorMessage } from 'utils/exploResourceUtils';

import { capitalizeFirstLetter } from './utils';

enum ResourceModal {
  EMBED = 'embed',
  RENAME = 'rename',
  DELETE = 'delete',
  DUPLICATE = 'duplicate',
  EMAIL = 'email',
  COLORS = 'colors',
  MOVE = 'move',
}

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  isExploreProduct: boolean;
  isFolder?: boolean;
  currentFolderId: number | undefined;
  resource: Resource | Folder;
  setLoadingStateForResource?: (isLoading: boolean) => void;
  setOpenModal: (resource: ResourceModal | undefined) => void;
};

export const RenameModal: FC<Props> = ({
  isOpen,
  closeModal,
  isExploreProduct,
  isFolder = false,
  currentFolderId,
  resource,
  setLoadingStateForResource,
  setOpenModal,
}) => {
  const dispatch = useDispatch();

  const { uniqueNames, isLoadingUniqueNames } = useSelector(
    (state: ReduxState) => ({
      uniqueNames: getUniqueNames(state, isExploreProduct),
      isLoadingUniqueNames: isExploreProduct
        ? RD.isLoading(state.dashboard.currentFolderResourceNames)
        : RD.isLoading(state.reportBuilder.currentFolderResourceNames),
    }),
    shallowEqual,
  );

  useEffect(() => {
    const type = isExploreProduct ? ResourceType.DASHBOARD : ResourceType.REPORT;
    dispatch(listResourceContentNames({ id: currentFolderId, type }));
  }, [currentFolderId, dispatch, isExploreProduct]);

  const resourceType = isFolder
    ? ResourceType.FOLDER
    : isExploreProduct
    ? ResourceType.DASHBOARD
    : ResourceType.REPORT;

  return (
    <TextFieldModal
      buttonName="Save"
      closeModal={closeModal}
      getErrorMessage={(input) => getResourceNameErrorMessage(input, uniqueNames, resource.name)}
      modalOpen={isOpen}
      modalTitle={`Rename ${capitalizeFirstLetter(resourceType)}`}
      onSubmit={(name) => {
        setLoadingStateForResource?.(true);
        setOpenModal(undefined);

        if (resourceType === ResourceType.FOLDER) {
          dispatch(
            renameFolder({
              id: resource.id,
              name,
              onSuccess: () => setLoadingStateForResource?.(false),
            }),
          );
        } else if (resourceType === ResourceType.DASHBOARD) {
          dispatch(
            renameDashboard({ id: resource.id, postData: { name } }, () => {
              setLoadingStateForResource?.(false);
              trackEvent(EVENTS.RENAMED_DASHBOARD_TEMPLATE_NAME, {
                dashboard_template_id: resource.id,
                dashboard_template_name: name,
              });
            }),
          );
        } else {
          dispatch(
            renameReportBuilder({ id: resource.id, postData: { name } }, () => {
              setLoadingStateForResource?.(false);
            }),
          );
        }
      }}
      portalContainerId={APP_PORTAL_ID}
      resourceName={resource.name}
      textFieldPlaceholder={`${capitalizeFirstLetter(resourceType)} name`}>
      <div>
        {isLoadingUniqueNames ? (
          <div className={sprinkles({ parentContainer: 'fill', flexItems: 'centerColumn' })}>
            <Spinner />
          </div>
        ) : undefined}
      </div>
    </TextFieldModal>
  );
};
