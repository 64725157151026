import cx from 'classnames';
import { FC } from 'react';

import { sprinkles } from 'components/ds';
import * as styles from 'shared/ExploResource/styles.css';

interface Props {
  datasetName: string;
  onClick: () => void;
}

export const DatasetListItem: FC<Props> = ({ datasetName, onClick }) => {
  return (
    <div className={styles.resourceListItem} onClick={onClick}>
      <div className={cx(styles.resourceCardName, sprinkles({ marginLeft: 'sp2' }))}>
        {datasetName}
      </div>
    </div>
  );
};
