import { useSelector } from 'react-redux';

import { ColorPicker, Props as ColorPickerProps } from 'components/ColorPicker';
import { getCategoricalColors } from 'globalStyles';
import { ReduxState } from 'reducers/rootReducer';
import { compact, uniq } from 'utils/standard';

type Props = Omit<ColorPickerProps, 'colors'>;

export default function CustomStylesColorPicker(props: Props) {
  const globalStyleConfig = useSelector(
    (state: ReduxState) =>
      state.styleConfig.editingConfig ?? state.dashboardStyles.globalStyleConfig,
  );

  const colorStrings = [
    globalStyleConfig.base.backgroundColor,
    globalStyleConfig.base.actionColor.default,
    globalStyleConfig.container.fill,
    globalStyleConfig.text.primaryColor,
    globalStyleConfig.text.secondaryColor,
    globalStyleConfig.base.actionColor.buttonColor,
    globalStyleConfig.base.actionColor.interactionStateColor,
    globalStyleConfig.container.outline.color,
    globalStyleConfig.container.shadow.color,
  ];

  // Add in only the categorical colors that are defined
  colorStrings.push(...getCategoricalColors(globalStyleConfig));

  const colors = compact(uniq(colorStrings));

  return <ColorPicker colors={colors} {...props} />;
}
