import cx from 'classnames';
import Color from 'color';
import { FC, useState } from 'react';
import { BlockPicker } from 'react-color';

import { Button, Popover, sprinkles, vars } from 'components/ds';

import * as styles from './index.css';

export type Props = {
  color?: string;
  alpha?: number;
  onClose: (color: string) => void;
  fill?: boolean;
  buttonClassName?: string;
  className?: string;
  colors?: string[];
  label?: string;
  // If enabled, colors can be cleared and set to undefined
  allowUnset?: boolean;
};

export const ColorPicker: FC<Props> = ({
  color,
  onClose,
  fill,
  alpha,
  buttonClassName,
  className,
  colors = [],
  label,
  allowUnset,
}) => {
  const [selectedColor, setSelectedColor] = useState(color ?? '');
  const colorObject = color ? new Color(color) : undefined;

  if (!color && !allowUnset) {
    return (
      <Button disabled fillWidth={fill}>
        —
      </Button>
    );
  }

  const colorText = colorObject ? colorObject.hex() : 'None';
  const alphaText = alpha === undefined ? '' : ` ${alpha * 100}%`;
  const buttonText = label === undefined ? `${colorText}${alphaText}` : label;

  const trigger = (
    <Button
      fillWidth
      className={cx(styles.colorButton, buttonClassName)}
      style={{
        backgroundColor: colorObject ? colorObject.hex() : vars.colors.gray5,
        color: colorObject
          ? colorObject.isDark()
            ? vars.colors.white
            : vars.colors.black
          : vars.colors.gray10,
      }}>
      {buttonText}
    </Button>
  );

  return (
    <Popover
      className={cx({ [styles.fill]: fill }, className)}
      onOpenChange={(open) => {
        if (!open) onClose(selectedColor);
      }}
      side="top"
      trigger={trigger}>
      <div className={sprinkles({ flexItems: 'column' })}>
        <BlockPicker
          className={sprinkles({ textTransform: 'uppercase' })}
          color={selectedColor === '' ? 'transparent' : selectedColor}
          colors={colors}
          onChange={(color) => setSelectedColor(color.hex)}
          triangle="hide"
          // @ts-ignore
          width={180}
        />
        {allowUnset ? (
          <Button
            fillWidth
            className={styles.deleteButton}
            icon="trash-can"
            onClick={() => {
              setSelectedColor('');
              // If we don't do this, nothing visually indicates the color
              // has been cleared until the user clicks out of the popover
              onClose('');
            }}
            variant="tertiary">
            Delete
          </Button>
        ) : null}
      </div>
    </Popover>
  );
};
